import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss', '../auth-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordDialogComponent implements OnInit {
  loading: boolean;
  header = 'forgot_password';
  linkSent = false;
  countryPrefix: string;
  assetsDir = '/';
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    public authApiService: AuthAPIService
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    const emailRequestBody = this.form.getRawValue();
    const phoneRequestBody = {
      phone_number: this.form.get('phone_number')?.value.substring(this.form.get('phone_country_code')?.value?.length),
      phone_country_code: this.form.get('phone_country_code')?.value,
    };

    const requestBody = this.form.get('email')?.value ? emailRequestBody : phoneRequestBody;

    if (this.form.valid) {
      this.loading = true;
      this.authApiService.resetPassword(requestBody).subscribe(
        (response: any) => {
          this.loading = false;
          this.linkSent = true;
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  }

  onCountryPrefixChange(prefix) {
    this.countryPrefix = prefix;
  }
}
