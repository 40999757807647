<form [formGroup]="accountFormPassword">
  <label for="pwd">{{ 'form.field.password' | transloco }}</label>

  <mat-form-field appearance="outline">
    <input id="pwd" matInput formControlName="password" [type]="hide ? 'password' : 'text'" required />
    <svg-icon
      matSuffix
      *ngIf="!hide"
      class="form-field-icon"
      src="{{ assetsDir }}assets/icons/icon-eye-hide.svg"
      (click)="hide = !hide"
    ></svg-icon>
    <svg-icon
      matSuffix
      *ngIf="hide"
      class="form-field-icon"
      src="{{ assetsDir }}assets/icons/icon-eye-show.svg"
      (click)="hide = !hide"
    ></svg-icon>
    <mat-error *ngIf="accountFormPassword.controls.password.invalid">
      <ng-container *ngIf="getErrorKeys('password').length">
        <ng-container *ngIf="getErrorKeys('password')[0] as firstErrorKey">
          <ng-container *ngIf="firstErrorKey === 'required'">{{ 'form.error.required' | transloco }} </ng-container>
          <ng-container *ngIf="firstErrorKey === 'min_length'">{{ 'form.error.min_length' | transloco }} </ng-container>
          <ng-container *ngIf="firstErrorKey === 'contains_lower_case'">{{ 'form.error.min_one_lowercase' | transloco }} </ng-container>
          <ng-container *ngIf="firstErrorKey === 'contains_upper_case'">{{ 'form.error.min_one_uppercase' | transloco }} </ng-container>
          <ng-container *ngIf="firstErrorKey === 'contains_number'">{{ 'form.error.min_one_number' | transloco }} </ng-container>
        </ng-container>
      </ng-container>
    </mat-error>
  </mat-form-field>
  <label for="pwdRepeat">{{ 'form.field.confirm_password' | transloco }}</label>

  <mat-form-field appearance="outline">
    <input id="pwdRepeat" matInput formControlName="repeatPassword" [type]="hide ? 'password' : 'text'" required />
    <svg-icon
      matSuffix
      *ngIf="!hide"
      class="form-field-icon"
      src="{{ assetsDir }}assets/icons/icon-eye-hide.svg"
      (click)="hide = !hide"
    ></svg-icon>
    <svg-icon
      matSuffix
      *ngIf="hide"
      class="form-field-icon"
      src="{{ assetsDir }}assets/icons/icon-eye-show.svg"
      (click)="hide = !hide"
    ></svg-icon>
    <mat-error *ngIf="accountFormPassword.controls.repeatPassword.invalid">
      <ng-container *ngIf="accountFormPassword.controls.repeatPassword.errors.required">
        {{ 'details.new_account_appic.field_is_required' | transloco }}
      </ng-container>
      <ng-container
        *ngIf="
          !accountFormPassword.controls.repeatPassword.errors.required && accountFormPassword.controls.repeatPassword.errors.matchOther
        "
      >
        {{ 'details.new_account_appic.password_not_same' | transloco }}
      </ng-container>
    </mat-error>
  </mat-form-field>

  <div *ngIf="passwordConditions" class="password-requirements">
    <div>{{ 'form.password_conditions' | transloco }}:</div>
    <ul>
      <li
        *ngFor="let condition of passwordConditions"
        class="password-condition"
        id="{{ condition.type }}"
        [class.active]="isConditionMet(condition)"
      >
        <svg-icon class="icon-check" src="{{ assetsDir }}assets/icons/icon-check-circle.svg"></svg-icon>
        <span>{{ condition.name }}</span>
      </li>
    </ul>
  </div>
  <div class="agree-info">
    {{ 'create_account_from_password.by_clicking_create' | transloco }}
    <br />
    <a [href]="'https://appic.events/' + getLanguage() + '/terms-of-use'">{{ 'global.appic_user_agreement' | transloco }} </a>,
    <a [href]="'https://appic.events/' + getLanguage() + '/privacy-policy'">{{ 'global.privacy_policy' | transloco }}.</a>
  </div>

  <button [disabled]="accountFormPassword.invalid" (click)="executeCaptcha()" type="submit" class="btn btn-primary appic-btn">
    {{ 'create_account_from_password.create_appic_account' | transloco }}
  </button>
</form>
