<app-dialog-layout-inner-x (close)="onNoClick()" [title]="data.title ? (data.title | transloco) : 'Error'">
  <ng-template #content>
    <div class="dialog-container p-2">
      <div class="text-center">
        <p class="m-0" *ngIf="data.otherText">({{ data.otherText }})</p>
        <p class="m-0">{{ data.error | transloco }}</p>
      </div>
    </div>
  </ng-template>
  <ng-template #actions>
    <button *ngIf="data.withButton" class="btn btn-dialog action-btn" (click)="onNoClick()">
      {{ btnText || ('global.try_again' | transloco) }}
    </button>
  </ng-template>
</app-dialog-layout-inner-x>
