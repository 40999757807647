<div class="steps-container">
  <div class="container">
    <div class="d-flex steps justify-content-center">
      <ng-container *ngFor="let step of steps">
        <div class="align-self-center step" [ngClass]="{ active: step.stepNumber === activeStep }">
          {{ step.val }}
        </div>
        <div class="align-self-center divider"></div>
      </ng-container>
    </div>
  </div>
</div>
