<div class="row mt-4">
  <div class="col-12">
    <div class="info-section text-center">
      <!--appic-member-->
      <ng-container *ngIf="variant === 'appic-member' || variant === 'appic-new-member'">
        <div class="d-block mb-4">
          <img class="appic-logo" src="{{ assetsDir }}assets/appic/appic-logo.png" />
        </div>
        <div class="d-block mb-4 summary-text-member">
          {{ 'summary.your_tickets' | transloco }} {{ shop.name }}<br />
          {{ 'summary.are_available_in_your_ticketbox_in_Appic' | transloco }}
        </div>
      </ng-container>
      <!--appic-member-->
      <!-- TODO: discuss if still needed -->
      <!--appic-new-member-->
      <!--       <ng-container *ngIf="variant === 'appic-new-member'">
     
      </ng-container> -->
      <!--appic-new-member-->

      <!--not-member-->
      <ng-container *ngIf="variant === 'not-member'">
        <div class="d-block mb-4">
          <img class="appic-logo" src="{{ assetsDir }}assets/appic/appic-logo.png" />
        </div>
        <div class="d-block mb-4 summary-text-not-member">
          <strong>{{ 'summary.do_you_want_to_have_your_ticket_available_on_your_mobile' | transloco }}</strong>
          <br />{{ 'summary.add_a_password' | transloco }}
        </div>
        <appic-update-password-form class="mt-2" (updateVariantEmiter)="updateVariant()"></appic-update-password-form>
        <div class="d-block mb-2">
          {{ 'summary.download_the_appic_and_login_with_password' | transloco }}
        </div>
      </ng-container>
      <!--not-member-->

      <!--not-member-matched-->
      <ng-container *ngIf="variant === 'not-member-matched'">
        <div class="d-block mb-4">
          <img class="appic-logo" src="{{ assetsDir }}assets/appic/appic-logo.png" />
        </div>
        <div class="d-block mb-4 summary-text-not-member-matched">
          <strong>{{ 'summary.tickets_can_be_added_to_your_ticketbox' | transloco }}</strong>
          <br />{{ 'summary.login_to_your_appic_account_to_add_your_tickets' | transloco }}
        </div>
        <appic-login-form class="text-left" (nextStepEmiter)="updateVariant()" [orderUuid]="orderUuid"></appic-login-form>
      </ng-container>
      <!--not-member-matched-->

      <div class="d-block mt-4">
        <div class="row justify-content-center">
          <div class="col-6 event-col">
            <div class="mockup-container">
              <div #mobileMockupImg class="mockup-img-container">
                <img class="mockup-img" src="{{ assetsDir }}assets/appic/appic-mockup-event.png" (load)="setScreenSize()" />
              </div>
              <div class="screen-container">
                <div
                  #mobileScreenHeight
                  class="event-bg-image"
                  [ngStyle]="{ 'background-image': shop.ui.logo_image ? 'url(' + shop.ui.logo_image + ')' : '', height: screenHeight }"
                ></div>
                <div class="event-container">
                  <div class="event-box">
                    <div class="event-img-container">
                      <ng-container *ngIf="shop.ui.logo_image">
                        <img class="event-img" [src]="shop.ui.logo_image" />
                      </ng-container>
                    </div>
                    <div class="event-info" *ngIf="shop.event">
                      <div class="event-name">
                        {{ shop.event.name }}
                      </div>
                      <div class="event-date">
                        {{ convertDate(shop.event.start) | date: 'dd/MM/yyyy' }}, {{ convertDate(shop.event.end) | date: 'dd/MM/yyyy' }}
                      </div>
                    </div>
                  </div>
                  <div class="dots">
                    <span class="dot active"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-7 col-md-6">
            <div class="mockup-container">
              <div class="ticketbox-container">
                <div class="event-img-container" *ngIf="shop.ui.logo_image">
                  <img class="event-img" [src]="shop.ui.logo_image" />
                </div>
                <div class="ticktet-info" *ngIf="order.order_lines">
                  {{ order.order_lines[0].event_name }} - {{ order.order_lines[0].product_name }}
                </div>
                <div class="buyer-info" *ngIf="order.buyer">
                  <span>Ticket is valid for:</span>
                  <ng-container *ngIf="order.buyer.first_name && order.buyer.last_name">
                    {{ order.buyer.first_name }} {{ order.buyer.last_name }}
                  </ng-container>
                  <ng-container *ngIf="!order.buyer.first_name || !order.buyer.last_name">
                    {{ order.buyer.email }}
                  </ng-container>
                </div>
                <img class="ticket-img" src="{{ assetsDir }}assets/appic/blurred_ticket.png" />
              </div>
              <img class="mockup-img" src="{{ assetsDir }}assets/appic/appic-mockup-ticketbox.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="d-block">
        <a class="apple-badge-link" href="https://beappic.com/download/?source=tsp" target="_blank">
          <img class="badge mr-md-2" src="{{ assetsDir }}assets/appic/badge-apple.svg" />
        </a>
        <a class="google-badge-link" href="https://beappic.com/download/?source=tsp" target="_blank">
          <img class="badge" src="{{ assetsDir }}assets/appic/badge-google.png" />
        </a>
      </div>
    </div>
  </div>
</div>
