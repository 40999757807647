import { Component, OnInit } from '@angular/core';
import { Language } from '../navigation/navigation.component';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';
import { AuthAPIService } from '../../services/auth-api.service';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-global-navigation',
  templateUrl: './global-navigation.component.html',
  styleUrls: ['./global-navigation.component.scss'],
})
export class GlobalNavigationComponent implements OnInit {
  assetsDir = '/';
  selectedLanguage;
  languages: Language[] = [
    {
      value: 0,
      display: 'EN',
      code: 'en',
    },
    {
      value: 1,
      display: 'NL',
      code: 'nl',
    },
  ];
  constructor(
    private translocoService: TranslocoService,
    private authApiService: AuthAPIService
  ) {}

  ngOnInit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }

    const lang = this.languages.find((obj) => obj.code === this.translocoService.getActiveLang());
    this.selectedLanguage = lang.code;
    this.translocoService.langChanges$.subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  getAvailableLangs() {
    return this.translocoService.getAvailableLangs().map((lang) => (typeof lang === 'string' ? lang : lang.id));
  }

  onLanguageChange(lang: string) {
    this.authApiService.setLanguage(lang).subscribe(
      (response: any) => {},
      (error) => {
        const levelError: Sentry.SeverityLevel = 'error';
        Sentry.captureMessage('Error occurred during saving language in session', levelError);
      }
    );
    this.translocoService.setActiveLang(lang);
    this.translocoService.setDefaultLang(lang);
  }
}
