import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeMinSec',
})
export class TimeMinSecPipe implements PipeTransform {
  transform(value: number) {
    const minutesRemaining = Math.floor(value / 60);
    const secondsRemaining = value % 60;
    let minutes, seconds;

    if (minutesRemaining < 10) {
      minutes = `0${minutesRemaining}`;
    } else {
      minutes = `${minutesRemaining}`;
    }

    if (secondsRemaining < 10) {
      seconds = `0${secondsRemaining}`;
    } else {
      seconds = `${secondsRemaining}`;
    }

    return `${minutes}:${seconds}`;
  }
}
