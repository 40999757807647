export const regexValidatorFunction = (condition: PasswordCondition): ValidatorFn => {
  const regex = new RegExp(condition.contains_regex_pattern);
  const errorObj = {};
  errorObj[condition.type] = true;
  return (control: AbstractControl): ValidationErrors | null => (regex.test(control.value) ? null : errorObj);
};

import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Countries, Country } from 'src/app/home/interfaces/forms.interface';
import { PasswordCondition } from '../home/interfaces/auth.interface';

export function matchOtherValidator(otherControlName: string) {
  let thisControl: UntypedFormControl;
  let otherControl: UntypedFormControl;

  return function matchOtherValidate(control: UntypedFormControl) {
    if (!control.parent) {
      return null;
    }

    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as UntypedFormControl;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }

    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true,
      };
    }

    return null;
  };
}
