<ng-container *ngIf="passwordForm && getFormControl('password')">
  <form class="auth-form" [formGroup]="passwordForm">
    <div class="form-field-container" (appCapsLock)="capsOn = $event">
      <div class="form-field-label">
        {{ 'form.field.password' | transloco }}
      </div>
      <div class="caps-tooltip" *ngIf="capsOn">Caps Lock</div>
      <mat-form-field appearance="outline" class="form-field form-field-password" [floatLabel]="'never'">
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          placeholder="{{ 'form.field.password' | transloco }}"
          formControlName="password"
          required
        />
        <svg-icon
          matSuffix
          *ngIf="!hidePassword"
          class="form-field-icon"
          src="{{ assetsDir }}assets/icons/icon-eye-hide.svg"
          (click)="hidePassword = !hidePassword"
        ></svg-icon>

        <svg-icon
          matSuffix
          *ngIf="hidePassword"
          class="form-field-icon"
          src="{{ assetsDir }}assets/icons/icon-eye-show.svg"
          (click)="hidePassword = !hidePassword"
        ></svg-icon>
        <mat-error *ngIf="getFormControl('password').invalid">
          <ng-container *ngIf="getErrorKeys('password').length">
            <ng-container *ngIf="getErrorKeys('password')[0] as firstErrorKey">
              <ng-container *ngIf="firstErrorKey === 'required'">
                {{ 'form.error.required' | transloco }}
              </ng-container>
              <ng-container *ngIf="firstErrorKey === 'min_length'">
                {{ 'form.error.min_length' | transloco }}
              </ng-container>
              <ng-container *ngIf="firstErrorKey === 'contains_lower_case'">
                {{ 'form.error.min_one_lowercase' | transloco }}
              </ng-container>
              <ng-container *ngIf="firstErrorKey === 'contains_upper_case'">
                {{ 'form.error.min_one_uppercase' | transloco }}
              </ng-container>
              <ng-container *ngIf="firstErrorKey === 'contains_number'">
                {{ 'form.error.min_one_number' | transloco }}
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-field form-field-password form-field-password-confirm" [floatLabel]="'never'">
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          placeholder="{{ 'form.field.repeat_password' | transloco }}"
          formControlName="confirm_password"
          required
        />
        <svg-icon
          matSuffix
          *ngIf="!hidePassword"
          class="form-field-icon"
          src="{{ assetsDir }}assets/icons/icon-eye-hide.svg"
          (click)="hidePassword = !hidePassword"
        ></svg-icon>
        <svg-icon
          matSuffix
          *ngIf="hidePassword"
          class="form-field-icon"
          src="{{ assetsDir }}assets/icons/icon-eye-show.svg"
          (click)="hidePassword = !hidePassword"
        ></svg-icon>
        <mat-error *ngIf="getFormControl('confirm_password').invalid">
          <ng-container *ngIf="getErrorKeys('confirm_password').length">
            <ng-container *ngIf="getErrorKeys('confirm_password')[0] as firstErrorKey">
              <ng-container *ngIf="firstErrorKey === 'required'">
                {{ 'form.error.required' | transloco }}
              </ng-container>
              <ng-container *ngIf="firstErrorKey === 'matchOther'">
                {{ 'form.error.password_not_same' | transloco }}
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div *ngIf="passwordConditions" class="password-requirements">
    <ul>
      <li
        *ngFor="let condition of passwordConditions"
        class="password-condition"
        id="{{ condition.type }}"
        [class.active]="isConditionMet(condition)"
      >
        <svg-icon class="icon-check" src="{{ assetsDir }}assets/icons/icon-check-circle.svg"></svg-icon>
        <span>{{ condition.name }}</span>
      </li>
    </ul>
  </div>

  <div class="custom-error" *ngIf="customErrors.invalidCaptcha || customErrors.emptyCaptcha">
    <ng-container *ngIf="customErrors.emptyCaptcha">
      {{ 'form.error.required' | transloco }}
    </ng-container>
    <ng-container *ngIf="customErrors.invalidCaptcha">
      {{ 'form.error.invalid' | transloco }}
    </ng-container>
  </div>
  <button class="btn btn-primary within-dialog d-block w-100 btn-submit mt-3" (click)="executeCaptcha()">
    {{ 'global.next' | transloco }}
  </button>
</ng-container>
