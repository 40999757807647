import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Product, Shop } from '../../interfaces/shop.interface';
import { Reservation, ShoppingCartReservation, ShoppingCart, ReservationResponse } from '../../interfaces/shopping-cart.interface';
import { ShopApiService } from '../../services/shop-api.service';
import { ShopConfigService } from '../../services/shop-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RESERVATION_RESPONSE_INTERNAL_ERROR, RESERVATION_RESPONSE_SUCCESS } from '../../../consts';
import { OrderPanelOptions } from '../../interfaces/component.interface';
import { Location, isPlatformBrowser, PlatformLocation } from '@angular/common';
import { LoggedDialogComponent } from '../../components/dialogs/logged-dialog/logged-dialog.component';
import { UserSessionService } from '../../services/user-session.service';
import { AuthAPIService } from '../../services/auth-api.service';
import { SessionRequest, SessionResponse, DeviceInfo } from '../../interfaces/user.interface';
import * as Sentry from '@sentry/browser';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';
import { UniversalDeviceDetectorService } from '../../services/universal-device-detector.service';
import { environment } from 'src/environments/environment';
import { GaService } from '../../services/ga.service';

@Component({
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit {
  tickets: Product[];
  nameShop: string;
  shoppingCart: ShoppingCart;
  color: string;
  shop: Shop;
  orderPanelOptions: OrderPanelOptions = {
    nextStep: 'details',
    nextButton: true,
    nextButtonDisabled: false,
  };
  busy: boolean;
  assetsDir = '/';
  addedToBasket = false;
  constructor(
    private shopConfigService: ShopConfigService,
    private shopApiService: ShopApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private userSessionService: UserSessionService,
    private authApiService: AuthAPIService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object,
    private platformLocation: PlatformLocation,
    private deviceService: UniversalDeviceDetectorService,
    private translateService: TranslocoService,
    private gaService: GaService
  ) {}

  ngOnInit(): void {
    this.gaService.event({ action: 'view_product', nonInteraction: true });
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.shop = this.shopConfigService.shop;
    this.tickets = this.shop.products;
    this.setCanonicalLink();
    this.sendSessionData((sessionCallback) => {});

    this.route.queryParams.subscribe((params) => {
      if (params['transaction']) {
        this.getShoppingCart(params['transaction']);
      }
    });

    this.route.params.subscribe((params) => {
      this.nameShop = params['name'];
    });
  }

  makeReservation(reservation: Reservation, callback) {
    if (!this.addedToBasket) {
      this.gaService.event({ action: 'add_to_basket' });
      this.addedToBasket = true;
    }

    this.busy = true;
    this.checkLogin((loginCallback) => {
      if (loginCallback) {
        if (this.shoppingCart) {
          reservation.shopping_cart_uuid = this.shoppingCart.uuid;
        }

        if (this.shopConfigService.serverSessionUuid) {
          reservation.shop_session_uuid = this.shopConfigService.serverSessionUuid;
        }

        this.shopApiService.postReservation(this.shop.uuid, reservation).subscribe(
          (response: any) => {
            const reservationResponse: ReservationResponse = response;
            this.shoppingCart = reservationResponse.shopping_cart;
            this.shopConfigService.shoppingCart = this.shoppingCart;
            this.updateUrl(this.shoppingCart.uuid);
            this.busy = false;
            callback(RESERVATION_RESPONSE_SUCCESS);
          },
          (error) => {
            if ('error' in error.error) {
              this.busy = false;
              callback(error.error.error);
            } else {
              this.busy = false;
              callback(RESERVATION_RESPONSE_INTERNAL_ERROR);
            }
          }
        );
      }
    });
  }

  checkLogin(callback) {
    const user = this.userSessionService.user;
    if (user && user.buyer_type !== 'not-member' && !this.shoppingCart) {
      const user = this.userSessionService.user;
      this.openDialog(user.first_name, user.last_name, (dialogCallback) => {
        if (dialogCallback) {
          this.orderPanelOptions.nextStep = 'payment';
          callback(true);
        } else {
          this.logout((logoutCallback) => {
            callback(logoutCallback);
          });
        }
      });
    } else {
      callback(true);
    }
  }

  logout(callback) {
    sessionStorage.clear();
    this.authApiService.deleteProfile(this.shopConfigService.shop.uuid).subscribe(
      (respoonse: any) => {
        this.userSessionService.user = null;
        this.orderPanelOptions.nextStep = 'details';
        this.shopConfigService.source = respoonse.source;
        this.shopConfigService.campaign = respoonse.campaign;

        this.authApiService.setLanguage(this.translateService.getDefaultLang()).subscribe(
          (response: any) => {},
          (error) => {
            const levelError: Sentry.SeverityLevel = 'error';

            Sentry.captureMessage('Error occurred during saving language in session', levelError);
          }
        );

        this.sendSessionData((sessionCallback) => {
          callback(true);
        });
      },
      (error) => {
        const levelError: Sentry.SeverityLevel = 'error';

        Sentry.captureMessage('Error occurred during logout', levelError);
        callback(true);
      }
    );
  }

  deleteReservation(productUuid: string, callback) {
    this.busy = true;
    const oldestReservation = this.getOldestReservation(productUuid);
    this.shopApiService.deleteReservation(this.shoppingCart.uuid, oldestReservation.uuid).subscribe(
      (response: any) => {
        const reservationResponse: ReservationResponse = response;
        this.shopConfigService.shoppingCart = reservationResponse.shopping_cart;
        this.shoppingCart = reservationResponse.shopping_cart;
        this.busy = false;
        callback('success');
      },
      (error) => {
        this.busy = false;
        callback(error.status);
      }
    );
  }

  getShoppingCart(shopping_cart_uuid: string) {
    this.shopApiService.getShoppingCart(shopping_cart_uuid).subscribe(
      (response: any) => {
        this.shoppingCart = response;
        this.shopConfigService.shoppingCart = this.shoppingCart;
        if (this.shoppingCart.status !== 'active' || this.shoppingCart.shop_uuid !== this.shop.uuid) {
          this.router.navigate(['../../'], { relativeTo: this.route });
        }
      },
      (error) => {
        this.router.navigate(['../../'], { relativeTo: this.route });
      }
    );
  }

  getOldestReservation(productUuid: string): ShoppingCartReservation {
    if (this.shoppingCart) {
      const oldestScr = this.shoppingCart.summary.reservations
        .filter((obj) => obj.product_uuid === productUuid)
        .reduce(function (r, a) {
          return r.created_at < a.created_at ? r : a;
        });
      return oldestScr;
    } else {
      return null;
    }
  }

  checkNumber(productUuid: string): number {
    if (this.shoppingCart) {
      if (this.shoppingCart.summary.products) {
        const product = this.shoppingCart.summary.products.find((obj) => obj.uuid === productUuid);
        return product ? product.quantity : 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  updateUrl(uuid: string) {
    const urlTree = this.router.parseUrl(this.router.url);
    const urlWithoutParams = urlTree.root.children['primary'].segments.map((it) => it.path).join('/');

    const newParams = {
      transaction: uuid,
      source: this.shopConfigService.source,
      campaign: this.shopConfigService.campaign,
    };

    this.route.queryParams.subscribe((params) => {
      if (!params['transaction']) {
        this.location.go(
          this.router
            .createUrlTree([urlWithoutParams], {
              queryParams: newParams,
            })
            .toString()
        );
      }
    });
  }

  openDialog(first_name: string, last_name: string, callback?): void {
    const dialogRef = this.dialog.open(LoggedDialogComponent, {
      width: '600px',
      data: { first_name: first_name, last_name: last_name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      callback(result);
    });
  }

  setCanonicalLink() {
    if (isPlatformBrowser(this.platformId)) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', document.location.href.split('?')[0]);
      document.head.appendChild(link);
    }
  }

  sendSessionData(callback) {
    const deviceInfo: DeviceInfo = this.getDeviceInfo();

    const sessionRequest: SessionRequest = {
      frontend_session_id: 'Unknown',
      shop_uuid: this.shopConfigService.shop.uuid,
      source: this.shopConfigService.source,
      campaign: this.shopConfigService.campaign,
      platform: 'Unknown',
      browser: 'Unknown',
      language: 'Unknown',
      ip_address: 'Unknown',
      device: deviceInfo.device,
      device_type: deviceInfo.device_type,
      device_os: deviceInfo.device_os,
      device_os_version: deviceInfo.device_os_version,
      device_user_agent: deviceInfo.device_user_agent,
      device_browser: deviceInfo.device_browser,
      device_browser_version: deviceInfo.device_browser_version,
    };

    this.shopApiService.postSession(sessionRequest).subscribe(
      (response: SessionResponse) => {
        this.shopConfigService.serverSessionUuid = response.uuid;
        callback(true);
      },

      (error) => {
        const levelError: Sentry.SeverityLevel = 'error';

        callback(true);
      }
    );
  }

  getDeviceInfo(): DeviceInfo {
    const device = this.deviceService.getDeviceInfo();
    const deviceType = this.deviceService.isMobile()
      ? 'mobile'
      : this.deviceService.isTablet()
        ? 'tablet'
        : this.deviceService.isDesktop()
          ? 'desktop'
          : 'Unknown';

    const deviceInfo: DeviceInfo = {
      device: device.device,
      device_type: deviceType,
      device_os: device.os,
      device_os_version: device.os_version,
      device_user_agent: device.userAgent,
      device_browser: device.browser,
      device_browser_version: device.browser_version,
    };

    return deviceInfo;
  }
  nextStepClicked() {
    this.gaService.event({ action: 'begin_checkout' });
  }

  get areAllProductsSoldOut() {
    return !this.tickets.some((ticket) => !ticket.is_sold_out);
  }
}
