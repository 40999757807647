<ng-container *ngIf="control">
  <mat-form-field
    class="form-field phone-form-field"
    [class.error]="control?.touched && control?.invalid && controlErrorKeys?.length && controlErrorKeys[0] !== 'validatePhoneNumber'"
  >
    <div class="country-tel-prefix-container">
      <ngx-mat-intl-tel-input
        [preferredCountries]="preferredCountries"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        name="phone"
        [formControl]="control"
        (countryChanged)="onCountryChange($event)"
        [inputPlaceholder]="phonePlaceholder"
        #phoneInput
      ></ngx-mat-intl-tel-input>
    </div>
    <mat-error *ngIf="control?.invalid && controlErrorKeys?.length">
      <ng-container *ngIf="control.errors.required">
        {{ 'form.error.required' | transloco }}
      </ng-container>
      <ng-container *ngIf="control.errors.api_phone_validation_error">
        {{ 'form.error.invalid' | transloco }}
      </ng-container>
    </mat-error>
  </mat-form-field>
</ng-container>
