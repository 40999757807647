<div class="loading-container" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</div>
<div class="row auth-form" [ngClass]="{ loading: loading }">
  <div *ngIf="form" class="col-12">
    <form class="auth-form" [formGroup]="form">
      <app-auth-form-auth-id-types
        [authType]="authType"
        [form]="form"
        [customErrors]="customErrors"
        (registrationOptionChange)="onRegistrationOptionChange($event)"
        (countryPrefixChange)="onCountryPrefixChange($event)"
        (loadingChange)="validationLoading = $event"
      >
      </app-auth-form-auth-id-types>
    </form>

    <div class="agreements-container">
      <p>
        <span>{{ 'global.agreements' | transloco }} </span>
        <span class="text-color-primary cta-btn" [routerLink]="['/terms-of-use']" (click)="closeDialog()"
          >{{ 'global.appic_user_agreement' | transloco }},
        </span>
        <span class="text-color-primary cta-btn" [routerLink]="['/privacy-policy']" (click)="closeDialog()">{{
          'global.privacy_policy' | transloco
        }}</span>
      </p>
    </div>
    <button class="btn btn-primary within-dialog d-block w-100 btn-submit mt-3" (click)="changeStep()">
      {{ 'global.create_account' | transloco }}
    </button>
    <a
      class="btn btn-facebook wide-btn"
      (click)="setFacebookRedirect(true)"
      href="facebook-login-redirect/shop/{{ shopConfigService.shop.url_extension }}/shopping-cart/{{
        shopConfigService.shoppingCart ? shopConfigService.shoppingCart.uuid : ''
      }}?{{ shopConfigService.source ? '&source=' + shopConfigService.source : '' }}{{
        shopConfigService.campaign ? '&campaign=' + shopConfigService.campaign : ''
      }}"
    >
      <svg-icon src="{{ assetsDir }}assets/icons/icon-fb-2.svg" />
      <span class="ion ion-logo-facebook"></span>
      {{ 'global.continue_with_facebook' | transloco }}
    </a>
  </div>
</div>
