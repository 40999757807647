import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation, Inject, PLATFORM_ID, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject } from 'rxjs';
import { ProfileSettingsRequest, RegistrationOption } from 'src/app/home/interfaces/auth.interface';
import { Countries, Country } from 'src/app/home/interfaces/forms.interface';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { LocaleService } from 'src/app/home/services/locale.service';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { isPlatformServer } from '@angular/common';
import { City } from 'src/app/home/interfaces/geocoder.interface';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { ShopConfigService } from 'src/app/home/services/shop-config.service';
import { UserSessionService } from 'src/app/home/services/user-session.service';
import moment from 'moment';
import { AuthDialogComponentData } from '../auth-dialog.component';

@Component({
  selector: 'app-auth-form-user-details',
  templateUrl: './auth-form-user-details.component.html',
  styleUrls: ['../auth-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthFormUserDetailsComponent implements OnInit, OnDestroy {
  @Output() stepChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() detailsUpdatedSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() data: AuthDialogComponentData;
  destroy$ = new Subject<boolean>();
  assetsDir = '';
  form = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    use_nickname: new UntypedFormControl(false),
    nick_name: new UntypedFormControl(''),
    gender: new UntypedFormControl('', [Validators.required]),
    birth_date: new UntypedFormControl('', [Validators.required]),
    birth_date_dd: new UntypedFormControl(''),
    birth_date_mm: new UntypedFormControl(''),
    birth_date_yyyy: new UntypedFormControl(''),
    city: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    lat: new UntypedFormControl(0),
    lon: new UntypedFormControl(0),
    language: new UntypedFormControl('', [Validators.required]),
    captcha_token: new UntypedFormControl('', [Validators.required]),
  });

  loading = false;
  gender = null;

  customErrors = {
    emptyGender: false,
    emptyDate: false,
    invalidDate: false,
    emptyCaptcha: false,
    invalidCaptcha: false,
  };
  selectedCity = null;
  maxDate = new Date();
  countries: Countries;
  recaptchaKey;

  constructor(
    private authApiService: AuthAPIService,
    private translateService: TranslocoService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private localeService: LocaleService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private shopApiService: ShopApiService,
    private shopConfigService: ShopConfigService,
    private userSessionService: UserSessionService
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }

    this.initCountries();
    this.getFormControl('language').setValue(this.translateService.getDefaultLang());
    if (this.data.registerRequest) {
      const { birth_date, ...rest } = this.data.registerRequest;
      this.form.patchValue({ ...rest });

      this.dateChange(new Date(birth_date));

      this.form.updateValueAndValidity();
    }
    this.recaptchaKey = environment.recaptcha;
  }

  initCountries() {
    if (isPlatformServer(this.platformId)) {
      this.countries = {
        primary: [],
        other: [],
      };
      return;
    }
    this.localeService.withLocale(this.setCountries.bind(this));
  }

  setCountries(localeData: i18nIsoCountries.LocaleData, language: string) {
    i18nIsoCountries.registerLocale(localeData);

    const countries: Countries = {
      primary: [],
      other: [],
    };

    const countriesArray = i18nIsoCountries.getNames(language);

    for (const key in countriesArray) {
      if (countriesArray.hasOwnProperty(key)) {
        const country: Country = {
          code: key,
          name: countriesArray[key],
        };

        if (country.code === 'NL' || country.code === 'BE' || country.code === 'DE' || country.code === 'FR' || country.code === 'GB') {
          countries.primary.push(country);
        } else {
          countries.other.push(country);
        }
      }
    }
    countries.other.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    this.countries = countries;
  }

  getFormControl(name: string) {
    if (this.form.get(name)) {
      return this.form.get(name);
    }
  }

  setGender(type: number) {
    this.form.get('gender').setValue(type);
    this.gender = type;
    this.customErrors.emptyGender = false;
  }

  dateChange(value) {
    this.getFormControl('birth_date').setValue(moment(value).format('YYYY-MM-DD'));
    this.getFormControl('birth_date_dd').setValue(moment(value).format('DD'));
    this.getFormControl('birth_date_mm').setValue(moment(value).format('MM'));
    this.getFormControl('birth_date_yyyy').setValue(moment(value).format('YYYY'));
    this.customErrors.emptyDate = false;
  }

  getLanguage(): string {
    return this.translateService.getDefaultLang() === 'en' ? 'en-GB' : 'nl-NL';
  }

  setCountry(city: City) {
    const country = city.country.toLocaleUpperCase();
    this.selectedCity = city.city;
    if (country && city.lat && city.lon) {
      this.getFormControl('country').setValue(country);
      this.getFormControl('lat').setValue(city.lat);
      this.getFormControl('lon').setValue(city.lon);
    }
  }

  checkCity() {
    if (this.selectedCity !== this.getFormControl('city').value) {
      this.getFormControl('city').setErrors({
        select_city: true,
      });
    }
  }

  executeCaptcha() {
    const observer = {
      next: (token) => {
        this.onCaptchaChange();
        this.getFormControl('captcha_token').setValue(token);
        this.onSubmit();
      },
    };

    this.recaptchaV3Service.execute('register').pipe(takeUntil(this.destroy$)).subscribe(observer);
  }

  onSubmit() {
    if (!this.form.get('gender').value) {
      this.customErrors.emptyGender = true;
    }

    if (!this.form.get('captcha_token').value) {
      this.customErrors.emptyCaptcha = true;
    }

    if (this.form.valid && this.validDate() && !this.customErrors.emptyGender && !this.customErrors.emptyCaptcha) {
      // const updateDetailsRequest = { ...this.signUpWithForm.getRawValue(), ...this.form.getRawValue(), password: this.password };
      const updateDetailsRequest = { ...this.form.getRawValue() };
      this.updateDetails(updateDetailsRequest);
    }
  }

  validDate() {
    this.customErrors.invalidDate = false;
    this.customErrors.emptyDate = false;
    if (!this.form.get('birth_date_dd').value || !this.form.get('birth_date_mm').value || !this.form.get('birth_date_yyyy').value) {
      this.customErrors.emptyDate = true;
    } else {
      const dd = this.form.get('birth_date_dd').value;
      const mm = this.form.get('birth_date_mm').value;
      const yyyy = this.form.get('birth_date_yyyy').value;
      const date = `${yyyy}-${mm}-${dd}`;

      if (!moment(date, 'YYYY-M-DD', true).isValid()) {
        this.customErrors.invalidDate = true;
      } else if (moment(date, 'YYYY-M-DD', true).isAfter(moment())) {
        this.customErrors.invalidDate = true;
      } else {
        this.customErrors.invalidDate = false;
        this.getFormControl('birth_date').setValue(moment(moment(date, 'YYYY-M-DD', true)).format('YYYY-MM-DD'));
      }
    }

    return !this.customErrors.emptyDate ? !this.customErrors.invalidDate : false;
  }

  updateDetails(updateDetailsRequest: ProfileSettingsRequest) {
    this.loading = true;
    const context = this.userSessionService.order_uuid
      ? { order_uuid: this.userSessionService.order_uuid }
      : this.shopConfigService.shoppingCart
        ? { shopping_cart_uuid: this.shopConfigService.shoppingCart.uuid }
        : null;
    this.authApiService.updateSettings(updateDetailsRequest).subscribe(
      (response: any) => {
        this.shopApiService.bindOrder(context).subscribe((resp) => {
          this.detailsUpdatedSuccess.emit(true);
        });
      },
      (error) => {
        const err = error?.error?.fields_errors;
        if (error?.error?.captcha_token) {
          this.customErrors.invalidCaptcha = true;
        }
        this.loading = false;
      }
    );
  }

  onCaptchaChange() {
    this.customErrors.emptyCaptcha = false;
    this.customErrors.invalidCaptcha = false;
  }

  get registrationOptions() {
    return RegistrationOption;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
