<div class="row summary-header desktop">
  <div class="col-12 text-center">
    <h5>{{ 'upcoming.coming_soon' | transloco }}</h5>
  </div>
</div>
<div class="row summary-counter desktop">
  <div class="col-12 text-center">
    <div class="row counter" *ngIf="upcomingTimer">
      <div class="col-3 counter-number counter-number-days">
        {{ upcomingTimer | timeDays: 'days' }}
        <span class="counter-info">{{ 'upcoming.days' | transloco }}</span>
      </div>
      <div class="col-3 counter-number counter-number-hours">
        {{ upcomingTimer | timeDays: 'hours' }}
        <span class="counter-info">{{ 'upcoming.hours' | transloco }}</span>
      </div>
      <div class="col-3 counter-number counter-number-minutes">
        {{ upcomingTimer | timeDays: 'minutes' }}
        <span class="counter-info">{{ 'upcoming.minutes' | transloco }}</span>
      </div>
      <div class="col-3 counter-number counter-number-seconds">
        {{ upcomingTimer | timeDays: 'seconds' }}
        <span class="counter-info">{{ 'upcoming.seconds' | transloco }}</span>
      </div>
    </div>
    <div class="counter" *ngIf="!upcomingTimer">00 : 00</div>
  </div>
</div>
