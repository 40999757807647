<div class="dialog-panel message-dialog" [ngStyle]="{ 'border-radius': borderRadius }">
  <svg-icon *ngIf="closeIconVisible" class="icon-close" src="{{ assetsDir }}assets/icons/icon-close.svg" (click)="closeDialog()" />
  <div *ngIf="title" class="dialog-header">
    <h1 class="dialog-title">{{ title }}</h1>
  </div>
  <div class="dialog-content" [ngStyle]="{ 'min-height': minHeight || 'initial' }">
    <ng-container *ngTemplateOutlet="content"> </ng-container>
  </div>
  <div class="dialog-actions mt-2">
    <p *ngIf="dialogWarning" class="warning mt-auto">{{ dialogWarning }}</p>
    <ng-container *ngTemplateOutlet="actions || defaultActions"> </ng-container>
  </div>
</div>
<div>
  <ng-template #defaultActions>
    <button [disabled]="disableSubmit" class="Volgende w-100 btn btn-primary" (click)="handleConfirm()">
      <ng-container *ngIf="btnText"> </ng-container>
      {{ btnText || 'global.save' | transloco }}
    </button>
  </ng-template>
</div>
