import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { ShopConfigService } from 'src/app/home/services/shop-config.service';
import { AppComponent } from 'src/app/app.component';
import { CanDeactivate } from 'src/app/home/can-deactivate/can-deactivate';
import { LoginData } from 'src/app/home/interfaces/forms.interface';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { UserSessionService } from 'src/app/home/services/user-session.service';

@Component({
  selector: 'appic-login-form',
  templateUrl: './appic-login-form.component.html',
  styleUrls: ['../forms.component.scss'],
})
export class AppicLoginFormComponent extends CanDeactivate implements OnInit {
  appicLoginForm: UntypedFormGroup;
  @Output() toggleFormsEmiter = new EventEmitter<string>();
  @Output() nextStepEmiter = new EventEmitter();
  @Output() passDataEmiter = new EventEmitter<LoginData>();
  showAppicResetPassword = false;
  orderUuid: string;
  constructor(
    private fB: UntypedFormBuilder,
    private authAPIService: AuthAPIService,
    protected shopConfigService: ShopConfigService,
    protected shopApiService: ShopApiService,
    private userSessionService: UserSessionService,
    private appComponent: AppComponent
  ) {
    super(shopApiService, shopConfigService);
  }

  ngOnInit() {
    this.appicLoginForm = this.fB.group({
      email: this.fB.control('', [Validators.required, Validators.email]),
      password: this.fB.control('', [Validators.required]),
    });

    if (this.userSessionService.user) {
      this.appicLoginForm.controls['email'].setValue(this.userSessionService.user.email);
    }
  }

  getFormControl(name) {
    return this.appicLoginForm.get(name);
  }

  onSubmit() {
    const context = this.orderUuid
      ? { order_uuid: this.orderUuid }
      : this.shopConfigService.shoppingCart
        ? { shopping_cart_uuid: this.shopConfigService.shoppingCart.uuid }
        : null;

    this.login(this.appicLoginForm.get('email').value, this.appicLoginForm.get('password').value, context)
      .then((respose) => {})
      .catch((error) => {
        if (error.status === 500) {
          this.toggleForms('appicLogin');
          this.appComponent.openDialog('application_errors.fill_data_manually');
        } else if (error.status === 400) {
          this.getFormControl('password').setErrors({
            incorrect_email_password: true,
          });
        }
      });
  }

  login(email: string, password: string, context): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authAPIService
        .login({
          email: email,
          password: password,
          context: context,
        })
        .subscribe(
          (response: any) => {
            this.userSessionService.user = response;
            resolve(response);
            this.nextStepEmiter.emit();
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  toggleForms(form: string) {
    if (form === 'newAccount' && (this.getFormControl('email').value || this.getFormControl('password').value)) {
      const loginData: LoginData = {
        email: this.getFormControl('email').value,
        password: this.getFormControl('password').value,
      };
      this.passDataEmiter.emit(loginData);
    }
    this.toggleFormsEmiter.emit(form);
  }

  toggleReset() {
    this.showAppicResetPassword ? (this.showAppicResetPassword = false) : (this.showAppicResetPassword = true);
  }

  getForm(): UntypedFormGroup {
    return this.appicLoginForm;
  }

  canCloseTab(): boolean {
    return !this.getForm().dirty;
  }

  setFacebookRedirect(param) {
    this.appicLoginForm.reset();
    this.shopConfigService.facebookRedirect = param;
  }
}

export interface AppicLoginFormComponentData {
  /*   showCreateButton: string;
  orderUuid: string; */
}
