import { Component, OnInit, Input } from '@angular/core';
import { Shop } from '../../interfaces/shop.interface';
import { TranslocoService } from '@ngneat/transloco';
import { AuthAPIService } from '../../services/auth-api.service';
import * as Sentry from '@sentry/browser';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Input('shop') shop: Shop;
  @Input('shopUrl') shopUrl;
  assetsDir = '/';
  languages: Language[] = [
    {
      value: 0,
      display: 'EN',
      code: 'en',
    },
    {
      value: 1,
      display: 'NL',
      code: 'nl',
    },
  ];

  constructor(
    public translocoService: TranslocoService,
    private authApiService: AuthAPIService
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  changeLanguage(event) {
    const lang = this.languages.find((obj) => obj.value === event.value).code;
    this.translocoService.setActiveLang(lang);
    this.translocoService.setDefaultLang(lang);
    this.authApiService.setLanguage(lang).subscribe(
      (response: any) => {},
      (error) => {
        const levelError: Sentry.SeverityLevel = 'error';
        Sentry.captureMessage('Error occurred during saving language in session', levelError);
      }
    );
  }

  convertDate(dateString) {
    return dateString.replace(/\s/g, 'T');
  }
}

export interface Language {
  value: number;
  display: string;
  code: string;
}
