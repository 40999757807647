<form class="standard-form mt-4" [formGroup]="appicUpdatePasswordForm" (ngSubmit)="onSubmit()">
  <div class="row mt-2">
    <div class="col-sm-12 mb-2">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'details.new_account_appic.password' | transloco }}"
          formControlName="password"
          type="password"
          required
        />
        <mat-error *ngIf="getFormControl('password').invalid">
          <ng-container *ngIf="getFormControl('password').errors.required">
            {{ 'details.new_account_appic.field_is_required' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('password').errors.minlength">
            {{ 'details.new_account_appic.min_char' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-12 mb-2">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'details.new_account_appic.repeat_password' | transloco }}"
          formControlName="repeatPassword"
          type="password"
          required
        />
        <mat-error *ngIf="getFormControl('repeatPassword').invalid">
          <ng-container *ngIf="getFormControl('repeatPassword').errors.required">
            {{ 'details.new_account_appic.field_is_required' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('repeatPassword').errors.matchOther">
            {{ 'details.new_account_appic.password_not_same' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-12 mb-2">
      <re-captcha [siteKey]="recaptchaKey" (resolved)="onCaptchaChange($event)" formControlName="captcha_token" required></re-captcha>
      <div class="custom-error" *ngIf="customErrors.invalidCaptcha || customErrors.emptyCaptcha">
        <ng-container *ngIf="customErrors.emptyCaptcha">
          {{ 'form.error.required' | transloco }}
        </ng-container>
        <ng-container *ngIf="customErrors.invalidCaptcha">
          {{ 'form.error.invalid' | transloco }}
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row mt-4 mb-4 justify-content-center">
    <div class="col-sm-6 mb-3 order-0 order-sm-1">
      <button type="submit" class="btn btn-primary" [disabled]="!appicUpdatePasswordForm.valid">
        {{ 'details.new_account_appic.continue' | transloco }}
      </button>
    </div>
  </div>
</form>
