import { Component, OnInit, OnDestroy, OnChanges, Input } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'order-summary-upcoming',
  templateUrl: './order-summary-upcoming.component.html',
  styleUrls: ['../order-summary.component.scss'],
})
export class OrderSummaryUpcomingComponent implements OnDestroy, OnInit, OnChanges {
  timerSub: Subscription;
  upcomingTimer: number;
  objectKeys = Object.keys;
  @Input('Date') date;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const now = new Date(Date.now());
    const exp = new Date(this.date);
    const seconds = Math.floor((+exp - +now) / 1000);

    if (exp > now) {
      this.startTimer(seconds);
    }
  }

  ngOnChanges(): void {
    const now = new Date(Date.now());
    const exp = new Date(this.date);
    const seconds = Math.floor((+exp - +now) / 1000);

    if (exp > now) {
      this.startTimer(seconds);
    }
  }

  ngOnDestroy() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  public startTimer(seconds) {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
    this.timerSub = timer(0, 1000)
      .pipe(
        take(seconds + 1),
        map((value) => seconds - value)
      )
      .subscribe({
        next: (value) => {
          this.upcomingTimer = value;
          if (this.upcomingTimer === 0) {
            this.router.navigate([`../../`], {
              relativeTo: this.route,
            });
          }
        },
      });
  }
}
