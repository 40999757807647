import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { City } from '../interfaces/geocoder.interface';

@Injectable({
  providedIn: 'root',
})
export class GeocoderApiService {
  baseURL = 'https://appic-geocoder.azurewebsites.net';

  constructor(private http: HttpClient) {}

  getCities = (query: string) =>
    this.http.get<{ data: City[] }>(`${this.baseURL}/v1.0/autocomplete/city`, {
      params: {
        access_token: environment.geocoderKey,
        term: query,
      },
    });
}
