import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Shop } from '../../interfaces/shop.interface';
import { environment } from 'src/environments/environment';
import { OrderResponse } from '../../interfaces/order.interface';

@Component({
  selector: 'summary-variant',
  templateUrl: './summary-variant.component.html',
  styleUrls: ['./summary-variant.component.scss'],
})
export class SummaryVariantComponent implements OnInit {
  @Input('variant') variant: string;
  @Input('shop') shop: Shop;
  @Input('orderUuid') orderUuid: string;
  @Input('order') order: OrderResponse;
  @ViewChild('mobileMockupImg', { static: true }) mobileMockupImg: ElementRef;
  @ViewChild('mobileScreenHeight', { static: true }) mobileScreenHeight: ElementRef;

  @Output('updateVariantEmiter') updateVariantEmiter = new EventEmitter();
  screenHeight = '500px';

  assetsDir = '';

  constructor() {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  setScreenSize() {
    if (this.mobileMockupImg.nativeElement) {
      this.screenHeight = this.mobileMockupImg.nativeElement.offsetHeight - 2 + 'px';
    }
  }

  updateVariant() {
    this.updateVariantEmiter.emit();
  }

  convertDate(dateString) {
    return dateString.replace(/\s/g, 'T');
  }
}
