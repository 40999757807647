<div class="loading-container" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</div>
<div class="row auth-form" [ngClass]="{ loading: loading }">
  <div class="col-12">
    <form class="auth-form" [formGroup]="form">
      <ng-container *ngIf="currentStep === 'logInWith'">
        <app-auth-form-auth-id-types [form]="form"></app-auth-form-auth-id-types>
      </ng-container>

      <ng-container *ngIf="currentStep === 'password'">
        <div class="form-field-container" (appCapsLock)="capsOn = $event">
          <div class="form-field-label">
            {{ 'form.field.password' | transloco }}
          </div>
          <div class="caps-tooltip" *ngIf="capsOn && passwordInput.focused">Caps Lock</div>
          <mat-form-field appearance="outline" class="form-field form-field-password" [floatLabel]="'never'">
            <input
              #passwordInput="matInput"
              matInput
              [type]="hide ? 'password' : 'text'"
              placeholder="{{ 'form.field.password' | transloco }}"
              formControlName="password"
              required
            />
            <svg-icon
              matSuffix
              *ngIf="!hide"
              class="form-field-icon"
              src="{{ assetsDir }}assets/icons/icon-eye-hide.svg"
              (click)="hide = !hide"
            />

            <svg-icon
              matSuffix
              *ngIf="hide"
              class="form-field-icon"
              src="{{ assetsDir }}assets/icons/icon-eye-show.svg"
              (click)="hide = !hide"
            />

            <mat-error *ngIf="getFormControl('password').invalid && getFormControl('password').touched">
              <ng-container *ngIf="getFormControl('password').errors.required">
                {{ 'form.error.required' | transloco }} |
                <span class="forgot-link" (click)="onForgotClick()">{{ 'form.error.forgot_password' | transloco }}</span>
              </ng-container>
              <ng-container *ngIf="getFormControl('password').errors.incorrect_email_password">
                {{ 'form.error.incorrect_email_password' | transloco }} |
                <span class="forgot-link" (click)="onForgotClick()">{{ 'form.error.forgot_password' | transloco }}</span>
              </ng-container>
            </mat-error>
            <mat-hint>
              <span class="forgot-link" (click)="onForgotClick()">{{ 'form.error.forgot_password' | transloco }}</span>
            </mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="btn-container">
        <button class="btn btn-primary within-dialog d-block w-100 btn-submit" (click)="continue()">
          {{ 'global.continue' | transloco }}
        </button>
        <a
          class="btn btn-facebook wide-btn"
          (click)="setFacebookRedirect(true)"
          href="facebook-login-redirect/shop/{{ shopConfigService.shop.url_extension }}/shopping-cart/{{
            shopConfigService.shoppingCart ? shopConfigService.shoppingCart.uuid : ''
          }}?{{ shopConfigService.source ? '&source=' + shopConfigService.source : '' }}{{
            shopConfigService.campaign ? '&campaign=' + shopConfigService.campaign : ''
          }}"
        >
          <svg-icon src="{{ assetsDir }}assets/icons/icon-fb-2.svg" />
          <span class="ion ion-logo-facebook"></span>
          {{ 'global.continue_with_facebook' | transloco }}
        </a>
      </div>
    </form>
  </div>
</div>
