<div class="flex-panel-container pt-5">
  <div class="panel panel-main">
    <app-navigation [shop]="shop" [shopUrl]="nameShop"></app-navigation>
    <ng-container *ngIf="areAllProductsSoldOut || !tickets.length">
      <p class="no-products-info">
        <span>{{ 'product.no_products' | transloco }}</span>
        <br />
        <span>{{ 'product.no_products_2' | transloco }}</span>
      </p>
    </ng-container>
    <div class="products-container">
      <div class="product-divider"></div>
      <ng-container *ngFor="let ticket of tickets">
        <product-ticket [busy]="busy" [ticketNumber]="checkNumber(ticket.uuid)" [ticket]="ticket" [color]="color"></product-ticket>
        <div class="product-divider"></div>
        <ng-container *ngFor="let futureTicket of ticket.future_prices">
          <product-ticket-future
            [busy]="true"
            [ticket]="futureTicket"
            [eventName]="ticket.event_name"
            [color]="color"
          ></product-ticket-future>
          <div class="product-divider"></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="order-panel-container" [ngClass]="{ hide: !shoppingCart?.summary.products.length }">
    <div class="panel order-panel">
      <order-summary
        [shopingCart]="shoppingCart"
        [orderPanelOptions]="orderPanelOptions"
        (nextStepEmiter)="nextStepClicked()"
      ></order-summary>
    </div>
  </div>
</div>
