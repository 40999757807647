<div class="product">
  <div class="row p-0 m-0 align-items-center align-items-md-start">
    <div class="col p-0 m-md-0 mb-2 order-1 col-12 col-md-5">
      <div class="product-name">
        <h6>{{ ticket.title }}</h6>
      </div>
    </div>
    <div class="product-price order-1 col-7 col-sm-7 col-md-4">
      {{ ticket.price | currency: ticket.currency }}
      <span class="fee"
        >({{ 'product.inc' | transloco }} {{ ticket.service_fee | currency: ticket.currency }} {{ 'product.fee' | transloco }})</span
      >
    </div>
    <div class="p-0 col-sm-auto order-3 text-sm-right my-auto p-0 col-5 col-sm-5 col-md-3 future-date">
      <span>{{ 'product.from' | transloco }} </span>
      <span>{{ ticket.available_from | date: 'dd MMM' }}</span>
    </div>
  </div>
</div>
