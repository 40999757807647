import { Injectable } from '@angular/core';
import { User } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private _user: User;
  public get user(): User {
    return this._user;
  }

  public set user(v: User) {
    this._user = v;
  }

  private _order_uuid: string;

  public get order_uuid(): string {
    return this._order_uuid;
  }

  public set order_uuid(v: string) {
    this._order_uuid = v;
  }
}
