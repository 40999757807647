import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { City } from '../../interfaces/geocoder.interface';
import { GeocoderApiService } from '../../services/geocoder-api.service';

@Component({
  selector: 'app-city-selector',
  templateUrl: './city-selector.component.html',
  styleUrls: ['./city-selector.component.scss'],
})
export class CitySelectorComponent implements AfterViewInit {
  @Output() cityChange: EventEmitter<City> = new EventEmitter<City>();
  @Output() clearInput: EventEmitter<void> = new EventEmitter<void>();
  @Output() inputBlur: EventEmitter<void> = new EventEmitter<void>();
  @Input() control: FormControl;

  cityList: City[] = [];

  constructor(private geocoderApiService: GeocoderApiService) {}

  ngAfterViewInit() {
    this.control?.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value) {
        this.getCityList(value);
      }
    });
  }

  emitInputBlur() {
    this.inputBlur.emit();
  }

  getCityList(query: string) {
    this.geocoderApiService.getCities(query).subscribe((response: any) => {
      this.cityList = response.data;
    });
  }

  clearCityInput() {
    this.clearInput.emit();
  }

  onCityChange(city: City) {
    this.cityChange.emit(city);
  }
}
