<form class="standard-form mt-3" [formGroup]="detailsForm">
  <div class="row mt-2">
    <div class="col-sm-12 mt-1 input-wrapper">
      <label>{{ 'details.details_manually.e-mail' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <input
          matInput
          placeholder="{{ 'details.details_manually.e-mail' | transloco }}"
          formControlName="email"
          [(ngModel)]="emailInput"
          required
        />
        <mat-error *ngIf="getFormControl('email').invalid">
          <ng-container *ngIf="getFormControl('email').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('email').errors.email">
            {{ 'details.details_manually.not_a_valid_email' | transloco }}
          </ng-container>
          <ng-container *ngIf="getFormControl('email').errors.not_deliverable">
            {{ 'form.error.email_not_deliverable' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
      <div class="errors-container" *ngIf="getFormControl('email')?.errors?.user_exists">
        <div class="already-exists-container">
          <p>
            {{ 'form.error.' + 'email_already_in_use' | transloco }}
            <a class="text-bold cta-btn" (click)="openLoginDialog()">{{ 'global.login' | transloco }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6 mt-1 input-wrapper">
      <label>{{ 'details.details_manually.first_name' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <input matInput placeholder="{{ 'details.details_manually.first_name' | transloco }}" formControlName="first_name" required />
        <mat-error *ngIf="getFormControl('first_name').invalid">
          <ng-container *ngIf="getFormControl('first_name').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 mt-1 input-wrapper">
      <label>{{ 'details.details_manually.last_name' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <input matInput placeholder="{{ 'details.details_manually.last_name' | transloco }}" formControlName="last_name" required />
        <mat-error *ngIf="getFormControl('last_name').invalid">
          <ng-container *ngIf="getFormControl('last_name').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 mt-1 input-wrapper">
      <label>{{ 'details.details_manually.birthday' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <input
          matInput
          [matDatepicker]="picker"
          [max]="now"
          placeholder="{{ 'details.details_manually.birthday' | transloco }}"
          formControlName="birth_date"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker startView="multi-year" [startAt]="startDate"></mat-datepicker>
        <mat-error *ngIf="getFormControl('birth_date').invalid">
          <ng-container *ngIf="getFormControl('birth_date').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 mt-1 input-wrapper">
      <label>{{ 'details.details_manually.city' | transloco }}*</label>
      <div class="form-field-container">
        <app-city-selector
          (inputBlur)="checkCity()"
          [control]="getFormControl('city')"
          (cityChange)="setCountry($event)"
        ></app-city-selector>
      </div>
    </div>
    <div class="col-md-6 mt-1 input-wrapper">
      <label>{{ 'details.details_manually.country' | transloco }}*</label>
      <mat-form-field appearance="outline" class="primary">
        <mat-select placeholder="{{ 'details.details_manually.country' | transloco }}" formControlName="country" required>
          <mat-option *ngFor="let country of countries?.primary" [value]="country.code">{{ country.name }}</mat-option>
          <mat-option disabled></mat-option>
          <mat-option *ngFor="let country of countries?.other" [value]="country.code">{{ country.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="getFormControl('country').invalid">
          <ng-container *ngIf="getFormControl('country').errors.required">
            {{ 'details.details_manually.field_is_required' | transloco }}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
