import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthAPIService } from '../../services/auth-api.service';
import { ShopApiService } from '../../services/shop-api.service';
import { ShopConfigService } from '../../services/shop-config.service';
import { UserSessionService } from '../../services/user-session.service';
import { User } from '../../interfaces/user.interface';
import { matchOtherValidator, regexValidatorFunction } from 'src/app/utils/matchOtherValidator';
import { isPlatformServer } from '@angular/common';
import { AuthDialogType, PasswordCondition, RegisterRequest, RegistrationOption } from '../../interfaces/auth.interface';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from '../../services/auth.service';
import { AuthDialogComponentData } from '../auth-dialog/auth-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-account-from-password',
  templateUrl: './create-account-from-password.component.html',
  styleUrls: ['./create-account-from-password.component.scss'],
})
export class CreateAccountFromPasswordComponent implements OnInit {
  @Output() updateVariantEmiter = new EventEmitter();
  accountFormPassword: UntypedFormGroup;
  hide = true;
  customErrors = {
    emptyCaptcha: false,
    invalidCaptcha: false,
  };
  passwordValidators = [];
  passwordConditions = [];

  assetsDir = '/';
  constructor(
    private fB: UntypedFormBuilder,
    private authAPIService: AuthAPIService,
    protected shopConfigService: ShopConfigService,
    protected shopApiService: ShopApiService,
    private translateService: TranslocoService,
    private userSessionService: UserSessionService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authApiService: AuthAPIService,
    private appComponent: AppComponent,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }

    this.accountFormPassword = this.fB.group({
      password: this.fB.control('', [Validators.required]),
      repeatPassword: this.fB.control('', [Validators.required, matchOtherValidator('password')]),
    });
    this.authApiService.getPasswordConditions().subscribe(
      (response: { conditions: PasswordCondition[] }) => {
        for (const condition of response.conditions) {
          this.passwordValidators.push(regexValidatorFunction(condition));
        }
        this.passwordConditions = response.conditions;
        this.accountFormPassword.get('password').addValidators(this.passwordValidators);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onSubmit(token) {
    const user: User = this.userSessionService.user;
    if (this.accountFormPassword.valid) {
      this.authAPIService
        .register({
          email: user.email,
          password: this.accountFormPassword.get('password').value,
          first_name: user.first_name,
          last_name: user.last_name,
          gender: user.gender?.toString(),
          birth_date: new Date(user.birth_date).toISOString().substring(0, 10),
          city: user.city,
          country: user.country,
          language: this.translateService.getDefaultLang(),
          context: {
            order_uuid: this.userSessionService.order_uuid,
          },
          captcha_token: token,
        })
        .subscribe(
          (response: User) => {
            this.userSessionService.user = response;

            const data: AuthDialogComponentData = {
              registerRequest: {
                email: user.email,
                password: this.accountFormPassword.get('password').value,
                first_name: user.first_name,
                last_name: user.last_name,
                gender: user.gender?.toString(),
                birth_date: new Date(user.birth_date).toISOString().substring(0, 10),
                city: user.city,
                country: user.country,
                language: this.translateService.getDefaultLang(),
              },
              type: AuthDialogType.ConfirmationCode,
            };
            this.updateVariantEmiter.emit();
            sessionStorage.setItem('activeRegistrationOption', RegistrationOption.Email);
            this.authService.openAuthDialog({ data });
          },
          (error) => {
            if (error.error.captcha_token) {
              this.customErrors.invalidCaptcha = true;
            }
            this.appComponent.openDialog('application_errors.an_error_has_occured');
          }
        );
    } else {
      this.accountFormPassword.updateValueAndValidity();
    }
  }

  getErrorKeys(controlName: string): string[] {
    const control = this.accountFormPassword.controls[controlName];
    return Object.keys(control.errors);
  }

  executeCaptcha() {
    const observer = {
      next: (token) => {
        this.customErrors.emptyCaptcha = false;
        this.customErrors.invalidCaptcha = false;
        this.onSubmit(token);
      },
    };

    this.recaptchaV3Service.execute('register').subscribe(observer);
  }
  onCaptchaChange() {
    this.customErrors.emptyCaptcha = false;
    this.customErrors.invalidCaptcha = false;
  }
  getLanguage(): 'nl' | 'en' {
    const currentLang = this.translateService.getActiveLang();
    return currentLang === 'nl' ? currentLang : 'en';
  }

  isConditionMet(condition) {
    const regex = new RegExp(condition.contains_regex_pattern);
    return regex.test(this.accountFormPassword.get('password')?.value);
  }
}
