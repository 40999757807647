import {
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  ViewEncapsulation,
  OnChanges,
  OnInit,
  NgZone,
} from '@angular/core';

import { Product } from '../../interfaces/shop.interface';
import { Reservation } from '../../interfaces/shopping-cart.interface';
import { TicketsComponent } from '../../views/tickets/tickets.component';
import { AppComponent } from 'src/app/app.component';
import {
  RESERVATION_RESPONSE_INTERNAL_ERROR,
  RESERVATION_RESPONSE_MAXIMUM_QUANTITY_REACHED,
  RESERVATION_RESPONSE_NOT_FOUND,
  RESERVATION_RESPONSE_ORDER_ALREADY_CREATED,
  RESERVATION_RESPONSE_SHOPPING_CART_CANCELLED,
  RESERVATION_RESPONSE_SUCCESS,
  RESERVATION_RESPONSE_TICKET_HANDLER_NOT_RESPONDING,
} from '../../../consts';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ShopConfigService } from '../../services/shop-config.service';

@Component({
  selector: 'product-ticket',
  templateUrl: './product-ticket.component.html',
  styleUrls: ['./product-ticket.component.scss'],
})
export class ProductTicketComponent implements OnChanges, AfterViewInit, OnInit {
  @Input('ticket') ticket: Product;
  @Input('color') eventColor: string;
  @Input('ticketNumber') ticketNumber: number;
  @Input('busy') busy: boolean;
  expand = false;
  @ViewChild('productDescription') productDescription: ElementRef;
  isDescriptionCollapsed = false;
  ticketCounter = false;
  currentWindowWidth;
  showTooltip = false;
  assetsDir = '/';
  @HostListener('document:click') onMouseClick() {
    this.showTooltip = false;
  }
  constructor(
    private cdRef: ChangeDetectorRef,
    private ticketComponent: TicketsComponent,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private shopConfigService: ShopConfigService
  ) {}

  ngOnInit(): void {
    if (this.ticketNumber > 0) {
      this.ticketCounter = true;
    }
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  ngOnChanges(): void {
    if (this.ticket.description) {
      if (this.productDescription) {
        this.isDescriptionCollapsed = this.checkDescrCollapsed(this.productDescription.nativeElement);
      }
    }
    if (this.ticketNumber > 0) {
      this.ticketCounter = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.ticket.description) {
      if (this.productDescription) {
        this.isDescriptionCollapsed = this.checkDescrCollapsed(this.productDescription.nativeElement);
      }
    }
    this.cdRef.detectChanges();
  }
  ngAfterViewChecked() {
    /*      if (this.tooltipRef) {
      this.tooltipRef.show();
    } */
  }
  checkDescrCollapsed(element) {
    // +1 because of Edge issue
    if (element.offsetHeight + 1 < element.scrollHeight || element.offsetWidth + 1 < element.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }

  addTicket() {
    const reservation: Reservation = {
      product_uuid: this.ticket.uuid,
      shopping_cart_uuid: null,
    };
    this.ticketComponent.makeReservation(reservation, (responseType) => {
      if (responseType === RESERVATION_RESPONSE_SUCCESS) {
        this.ticketNumber++;
      } else if (responseType === RESERVATION_RESPONSE_MAXIMUM_QUANTITY_REACHED) {
        this.showTooltip = true;
      } else if (responseType === RESERVATION_RESPONSE_SHOPPING_CART_CANCELLED) {
        // Show message, you can not add more reservations to cancelled cart
        this.appComponent.openDialog('application_errors.cancelled_cart', (callback) => {
          if (callback) {
            this.router.navigate(['../tickets'], {
              relativeTo: this.route,
            });
          }
        });
      } else if (responseType === RESERVATION_RESPONSE_ORDER_ALREADY_CREATED) {
        // Show message, if the order is created, you can not add more reservations
        this.appComponent.openDialog('application_errors.order_is_created', (callback) => {
          if (callback) {
            this.router.navigate(['../tickets'], {
              relativeTo: this.route,
            });
          }
        });
      } else if (responseType === RESERVATION_RESPONSE_NOT_FOUND) {
        // Show message, it may happen if the products are not connected correctly to shops
        this.appComponent.openDialog('application_errors.products_are_not_connected', (callback) => {
          if (callback) {
            this.router.navigate(['../tickets'], {
              relativeTo: this.route,
            });
          }
        });
      } else if (responseType === RESERVATION_RESPONSE_TICKET_HANDLER_NOT_RESPONDING) {
        this.appComponent.openDialog('application_errors.ticket_handler_not_responding');
      } else if (responseType === RESERVATION_RESPONSE_INTERNAL_ERROR) {
        this.appComponent.openDialog('application_errors.ticket_handler_not_responding');
      } else {
        this.appComponent.openDialog('application_errors.ticket_handler_not_responding');
      }
    });
  }

  shouldShowSoldOut(product: Product): boolean {
    if (!product.is_sold_out) return false;
    if (!this.shopConfigService.shoppingCart) return true;
    return !this.shopConfigService.shoppingCart.summary.reservations.find((reservation) => {
      return reservation.product_uuid === product.uuid;
    });
  }

  removeTicket() {
    if (this.ticketNumber > 0) {
      if (this.showTooltip) {
        this.showTooltip = false;
      }
      this.ticketComponent.deleteReservation(this.ticket.uuid, (callback) => {
        if (callback === 'success') {
          this.ticketNumber--;
        } else {
          this.appComponent.openDialog('application_errors.an_error_has_occured', null, callback);
        }
      });
    }
  }
}
