import { Component, OnInit, ElementRef, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { ErrorDialogComponent, ErrorDialogDialogData } from './home/components/dialogs/error-dialog/error-dialog.component';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AuthAPIService } from './home/services/auth-api.service';
import { ShopConfigService } from './home/services/shop-config.service';
import { Meta } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { REQUEST } from './tokens/tokens';
import { Request } from 'express';
import { environment } from 'src/environments/environment';
import { GaService } from './home/services/ga.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  loader: boolean;
  backdrop = false;
  langToSet: string;
  onLangChange: Subscription = undefined;
  styleSet: boolean;
  constructor(
    public translocoService: TranslocoService,
    public dialog: MatDialog,
    public el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authApiService: AuthAPIService,
    private shopConfigService: ShopConfigService,
    private meta: Meta,
    @Optional() @Inject(REQUEST) private request: Request,
    private gaService: GaService
  ) {
    console.log('Configuration:', environment.configuration);
    this.setLangByClientData();
    this.authApiService.getLanguage().subscribe(
      (response: any) => {
        this.langToSet = response;
        this.translocoService.setActiveLang(this.langToSet);
        this.translocoService.setDefaultLang(this.langToSet);
      },
      () => {}
    );
  }

  ngOnInit(): void {
    if (environment.production) {
      this.gaService.initialize();
    }
    if (isPlatformBrowser(this.platformId)) {
      this.onLangChange = this.translocoService.langChanges$.subscribe(() => {
        this.updateLangAttr();
      });
    }
    this.initMetaListener();
  }

  setLoader(visible) {
    visible ? (this.loader = true) : (this.loader = false);
  }

  openDialog(errorText, callback?, otherText?, title?, withButton?): void {
    const data: ErrorDialogDialogData = { error: errorText, otherText: otherText, title, withButton: withButton || !!callback };
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      width: '600px',
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      if (callback) {
        callback(true);
      }
    });
  }

  updateLangAttr() {
    const lang = document.createAttribute('lang');
    lang.value = this.translocoService.getActiveLang();
    this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(lang);
  }

  initMetaListener() {
    if (this.shopConfigService.shop) {
      this.translocoService.selectTranslate('general.meta_description').subscribe((text) => {
        const description = text.replace(/{{shopName}}/g, this.shopConfigService.shop.name);
        this.meta.updateTag({ name: 'description', content: description });
      });
    }
  }

  setLangByClientData() {
    if (this.request?.headers) {
      const langsString = this.request.headers['accept-language'];
      const result = langsString?.split(',').find((lang) => {
        if (lang.toLowerCase().includes('en')) {
          this.translocoService.setActiveLang('en');
          this.translocoService.setDefaultLang('en');

          return true;
        }
        if (lang.toLowerCase().includes('nl')) {
          this.translocoService.setActiveLang('nl');
          this.translocoService.setDefaultLang('nl');

          return true;
        }
        return false;
      });
      if (!result) {
        this.translocoService.setActiveLang('en');
        this.translocoService.setDefaultLang('en');
      }
    } else {
      const browserLang = getBrowserLang();
      if (browserLang !== 'en' && browserLang !== 'nl') {
        this.langToSet = 'en';
      } else {
        this.langToSet = browserLang;
      }
      this.translocoService.setActiveLang(this.langToSet);
      this.translocoService.setDefaultLang(this.langToSet);
    }
  }
}
