import { Component, Input, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnDestroy {
  @Input('active-step') activeStep: number;
  objectKeys = Object.keys;
  steps = [];
  sub: Subscription;
  constructor(private translateService: TranslocoService) {
    this.setSteps();
    this.sub = this.translateService.langChanges$.subscribe((event) => {
      this.setSteps();
    });
  }

  setSteps() {
    this.steps = [
      {
        stepNumber: 1,
        val: this.translateService.translate('order.tabs.tickets'),
      },
      {
        stepNumber: 2,
        val: this.translateService.translate('order.tabs.details'),
      },
      {
        stepNumber: 3,
        val: this.translateService.translate('order.tabs.payment'),
      },
    ];
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
