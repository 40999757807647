import { Injectable } from '@angular/core';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(private translateService: TranslocoService) {}
  withLocale(cb: Function) {
    let language = getBrowserLang();

    try {
      /* https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations */
      import(`../../../../node_modules/i18n-iso-countries/langs/${language}.json`).then((locale) => {
        cb(locale, language);
      });
    } catch (ex) {
      import(`i18n-iso-countries/langs/en.json`).then((locale: any) => {
        cb(locale, language);
      });
    }
  }
}
