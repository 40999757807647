import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDays',
})
export class TimeDaysPipe implements PipeTransform {
  transform(value: number, param: string) {
    const daysRemaining = Math.floor(value / 24 / 60 / 60);
    const hoursLeft = Math.floor(value - daysRemaining * 86400);
    const hoursRemaining = Math.floor(hoursLeft / 3600);
    const minutesLeft = Math.floor(hoursLeft - hoursRemaining * 3600);
    const minutesRemaining = Math.floor(minutesLeft / 60);
    const secondsRemaining = value % 60;
    let days, hours, minutes, seconds;

    if (daysRemaining < 10) {
      days = `0${daysRemaining}`;
    } else {
      days = `${daysRemaining}`;
    }

    if (hoursRemaining < 10) {
      hours = `0${hoursRemaining}`;
    } else {
      hours = `${hoursRemaining}`;
    }

    if (minutesRemaining < 10) {
      minutes = `0${minutesRemaining}`;
    } else {
      minutes = `${minutesRemaining}`;
    }

    if (secondsRemaining < 10) {
      seconds = `0${secondsRemaining}`;
    } else {
      seconds = `${secondsRemaining}`;
    }

    switch (param) {
      case 'days': {
        return `${days}`;
      }
      case 'hours': {
        return `${hours}`;
      }
      case 'minutes': {
        return `${minutes}`;
      }
      case 'seconds': {
        return `${seconds}`;
      }
    }
  }
}
