import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CitySelectorComponent } from './city-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [CitySelectorComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  exports: [CitySelectorComponent],
  providers: [],
})
export class CitySelectorModule {}
