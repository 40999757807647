<div>
  <div class="register-options-container">
    <ng-container *ngFor="let registrationOption of registrationOptionsAsArray">
      <div
        class="nav-item"
        [ngClass]="{ active: registrationOption === activeRegistrationOption }"
        [class]="registrationOption"
        (click)="toggleRegistrationOption(registrationOption)"
      >
        <span *ngIf="registrationOption === registrationOptions.Phone">
          {{ 'global.phone' | transloco }}
        </span>
        <span *ngIf="!(registrationOption === registrationOptions.Phone)">
          {{ 'global.' + registrationOption | transloco }}
        </span>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <div class="form-field-container">
        <ng-container *ngIf="activeRegistrationOption === registrationOptions.Email">
          <div class="form-field-label">
            {{ 'form.field.email' | transloco }}
          </div>
          <mat-form-field appearance="outline" class="form-field" [floatLabel]="'never'">
            <input (keyup)="removeUppercase()" matInput placeholder="{{ 'form.field.email' | transloco }}" formControlName="email" />
            <mat-error *ngIf="getFormControl('email').invalid">
              <ng-container *ngIf="getFormControl('email').errors.required">
                {{ 'form.error.required' | transloco }}
              </ng-container>
              <ng-container *ngIf="getFormControl('email').errors.email || getFormControl('email').errors.api_email_validation_error">
                {{ 'form.error.invalid_email' | transloco }}
              </ng-container>
              <ng-container *ngIf="getFormControl('email').errors.not_deliverable">
                {{ 'form.error.email_not_deliverable' | transloco }}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <div class="custom-error" *ngIf="customErrors?.userExist || customErrors?.invalidEmail">
            <ng-container *ngIf="customErrors.invalidEmail">
              {{ 'form.error.invalid_email' | transloco }}
              <ng-container *ngIf="customErrors.userExist">,</ng-container>
            </ng-container>
            <ng-container *ngIf="customErrors.userExist">{{ 'form.error.user_already_exist' | transloco }}</ng-container>
          </div>
        </ng-container>
      </div>
      <div class="form-field-container country-tel-form-field-container">
        <ng-container *ngIf="activeRegistrationOption === registrationOptions.Phone">
          <div class="form-field-label">
            {{ 'form.field.phone' | transloco }}
          </div>
          <app-country-phone-prefix-selector
            [authType]="authType"
            [control]="getFormControl('phone_number')"
            (countryPrefixChange)="onCountryPrefixChange($event)"
          ></app-country-phone-prefix-selector>
        </ng-container>
      </div>
    </form>
  </ng-container>

  <div class="errors-container">
    <div
      class="already-exists-container"
      *ngIf="getFormControl('phone_number')?.errors?.user_exists || getFormControl('email')?.errors?.user_exists"
    >
      <p>
        {{ 'form.error.' + (getFormControl('email')?.errors?.user_exists ? 'email_already_in_use' : 'phone_already_in_use') | transloco }}
      </p>
      <p>
        <span class="text-bold cta-btn" (click)="openLoginDialog()">{{ 'global.login' | transloco }}</span>
        <span> {{ 'form.error.or_request_a' | transloco }} </span>
        <span class="text-bold cta-btn" (click)="openForgotPasswordDialog()">{{ 'form.error.new_password' | transloco }}</span>
      </p>
    </div>
    <div *ngIf="customErrors?.validationInProgress" class="validation-progress-container">
      <p>
        {{ 'form.error.validation_in_progress' | transloco }}
      </p>
    </div>
  </div>
</div>
