<div class="sub-payment-method-container" [ngClass]="{ active: active }" (click)="setSelectedVariant()">
  <app-radio-btn-check [value]="active"></app-radio-btn-check>

  <div class="sub-payment-thumbnail-container">
    <img class="sub-payment-thumbnail" [src]="paymentVariant.logo_image" />
  </div>
  <div class="align-self-center sub-container-text">
    <span class="sub-payment-name">{{ paymentVariant.display_name }}</span>
  </div>
</div>
