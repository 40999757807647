<div class="auth-panel fadeIn">
  <div class="auth-panel-header text-center">
    <div class="header">
      {{ 'global.' + (linkSent ? 'confirmation' : 'forgot_password') | transloco }}
    </div>
    <div class="close-icon-container">
      <svg-icon class="icon-close" src="{{ assetsDir }}assets/icons/icon-close.svg" (click)="closeDialog()" />
    </div>
  </div>

  <div class="loading-container" *ngIf="loading">
    <div class="spinner-container">
      <mat-spinner class="primary" [diameter]="40"></mat-spinner>
    </div>
  </div>
  <div class="auth-panel-body">
    <div class="row m-0 justify-content-center">
      <div class="col-12 col-md-10">
        <ng-container *ngIf="!linkSent">
          <form class="auth-form" [formGroup]="form">
            <app-auth-form-auth-id-types [form]="form" (countryPrefixChange)="onCountryPrefixChange($event)"></app-auth-form-auth-id-types>
            <div class="btn-container">
              <button class="btn btn-primary within-dialog d-block w-100 btn-submit" (click)="onSubmit()">
                {{ 'global.continue' | transloco }}
              </button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="linkSent">
          <div class="link_sent-info">
            <p>{{ 'global.link_sent' | transloco }}</p>
            <p class="text-bold">{{ form.get('email')?.value || form.get('phone_number')?.value }}</p>
          </div>
          <div class="resend-link-container">
            <p class="text-color-primary cta-btn" (click)="onSubmit()">{{ 'global.resend_link' | transloco }}</p>
          </div>
          <button class="btn btn-primary within-dialog d-block w-100 btn-submit" (click)="closeDialog()">
            {{ 'global.continue' | transloco }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
