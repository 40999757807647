import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Token } from 'src/app/home/interfaces/auth.interface';
import { AuthDialogComponent } from '../auth-dialog.component';
import { DialogService } from 'src/app/home/services/dialog.service';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { environment } from 'src/environments/environment';
import { ShopConfigService } from 'src/app/home/services/shop-config.service';
import { UserSessionService } from 'src/app/home/services/user-session.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ShopApiService } from 'src/app/home/services/shop-api.service';

enum LoginSteps {
  LogInWith = 'logInWith',
  Password = 'password',
}
@Component({
  selector: 'app-auth-form-login',
  templateUrl: './auth-form-login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auth-form-login.component.scss', '../auth-dialog.component.scss'],
})
export class AuthFormLoginComponent implements OnInit {
  @Output() forgotRedirect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loginSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  hide = true;
  capsOn;
  loading = false;
  currentStep = LoginSteps.LogInWith;

  constructor(
    private authApiService: AuthAPIService,
    /*     private fbLoginService: FbLoginService, */
    private dialogRef: MatDialogRef<AuthDialogComponent>,
    private dialog: MatDialog,
    private dialogService: DialogService,
    public shopConfigService: ShopConfigService,
    private userSessionService: UserSessionService,
    private shopApiService: ShopApiService
  ) {}
  assetsDir = '/';
  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  continue() {
    if (this.currentStep === LoginSteps.LogInWith) {
      const emailUsedAndValid = this.form.get('email') && this.form.get('email').valid;
      const phoneUsedAndValid = this.form.get('phone_number') && this.form.get('phone_number').valid;

      if (emailUsedAndValid || phoneUsedAndValid) {
        this.currentStep = LoginSteps.Password;
      }
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      if (this.form.get('phone_number')?.value) {
        const countryPrefix = this.form.get('phone_country_code')?.value;
        this.form.get('phone_number').setValue(this.form.get('phone_number').value?.substring(countryPrefix?.length));
      }
      const context = this.userSessionService.order_uuid
        ? { order_uuid: this.userSessionService.order_uuid }
        : this.shopConfigService.shoppingCart
          ? { shopping_cart_uuid: this.shopConfigService.shoppingCart.uuid }
          : null;
      this.authApiService.login({ ...this.form.getRawValue() }).subscribe(
        (response: any) => {
          this.userSessionService.user = response;
          this.shopApiService.bindOrder(context).subscribe((resp) => {
            this.loginSuccess.emit(true);
            this.dialog.closeAll();
          });
        },
        (error) => {
          if (error.status === 400) {
            this.getFormControl('password').setErrors({
              incorrect_email_password: true,
            });
          }
          this.loading = false;
        }
      );
    }
  }

  setFacebookRedirect(param) {
    this.shopConfigService.facebookRedirect = param;
  }
  onForgotClick() {
    this.forgotRedirect.emit(true);
    this.dialogService.openDialog(ForgotPasswordDialogComponent, { width: '600px' });
  }
}
