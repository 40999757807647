<app-dialog-layout-inner-x (close)="onNoClick()" [closeIconVisible]="false" [title]="'login_modal.you_are_logged_in' | transloco">
  <ng-template #content>
    <div class="dialog-container p-2">
      <div class="text-center mb-4">
        <p>
          {{ 'login_modal.do_you_want_to_continue_as' | transloco }} <strong>{{ data.first_name }} {{ data.last_name }}</strong
          >?
        </p>
      </div>
    </div>
  </ng-template>
  <ng-template #actions>
    <div class="btn-container">
      <button class="btn btn-dialog-block btn-back mr-sm-2" [mat-dialog-close]="false">
        {{ 'login_modal.log_out' | transloco }}
      </button>
      <button class="btn btn-dialog-block ml-sm-2" [mat-dialog-close]="true">
        {{ 'login_modal.continue' | transloco }}
      </button>
    </div>
  </ng-template>
</app-dialog-layout-inner-x>
