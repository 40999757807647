import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, debounceTime, take, takeUntil } from 'rxjs';
import { AuthTypes, ValidatePhoneRequest, ValidatePhoneResponse } from '../../interfaces/auth.interface';
import { AuthAPIService } from '../../services/auth-api.service';
import { AbstractControl } from '@angular/forms';
import e from 'express';

@Component({
  selector: 'app-country-phone-prefix-selector',
  templateUrl: './country-phone-prefix-selector.component.html',
  styleUrls: ['./country-phone-prefix-selector.component.scss'],
})
export class CountryPhonePrefixSelectorComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() authType = AuthTypes.Login;
  @Output() countryPrefixChange: EventEmitter<string> = new EventEmitter<string>();

  preferredCountries = ['nl', 'be'];
  countryPrefix: string;
  phonePlaceholder: string;
  destroy$ = new Subject<boolean>();

  customErrors = {
    api_phone_validation_error: false,
    user_exists: false,
    required: false,
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authApiService: AuthAPIService
  ) {}

  ngOnInit() {
    if (this.authType === AuthTypes.Register) {
      this.control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe((value) => {
        if (value) {
          this.validatePhone();
        }
      });
    }
  }

  get phoneHTMLInput() {
    return this.document.querySelector('.country-tel-prefix-container input') as HTMLInputElement;
  }

  onCountryChange(e) {
    if (this.phoneHTMLInput && !this.phoneHTMLInput.value) {
      setTimeout(() => {
        this.control.markAsUntouched();
      }, 50);
    }

    this.countryPrefix = `+${e.dialCode}`;
    this.countryPrefixChange.emit(this.countryPrefix);
    this.phonePlaceholder = e.placeHolder.replace(this.countryPrefix, '');
  }

  get controlErrorKeys() {
    if (this.control?.errors) {
      return Object.keys(this.control.errors);
    }
  }

  validatePhone() {
    if (this.control?.value) {
      this.recaptchaV3Service
        .execute('validatePhone')
        .pipe(take(1))
        .subscribe((token) => {
          const body: ValidatePhoneRequest = {
            phone_number: this.control?.value?.substring(this.countryPrefix?.length),
            phone_country_code: this.countryPrefix,
            captcha_token: token,
          };

          if (body?.phone_number?.length) {
            this.authApiService.validatePhone(body).subscribe((res: ValidatePhoneResponse) => {
              if (!res?.is_valid) {
                this.control.setErrors({ api_phone_validation_error: true });
              }

              if (res?.code === 'user_exists') {
                this.control.setErrors({ user_exists: true });
              }
            });
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
