import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { DialogService } from 'src/app/home/services/dialog.service';
import { AuthTypes, RegistrationOption } from 'src/app/home/interfaces/auth.interface';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';
import { ShopConfigService } from 'src/app/home/services/shop-config.service';

@Component({
  selector: 'app-auth-form-sign-up-with',
  templateUrl: './auth-form-sign-up-with.component.html',
  styleUrls: ['./auth-form-sign-up-with.component.scss', '../auth-dialog.component.scss'],
})
export class AuthFormSignUpWithComponent {
  @Output() stepChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() countryPrefixChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() form: FormGroup;

  destroy$ = new Subject<boolean>();
  loading = false;
  _validationLoading = false;
  public set validationLoading(v: boolean) {
    this._validationLoading = v;
    if (!this.validationLoading) {
      this.customErrors.validationInProgress = false;
    }
  }

  public get validationLoading(): boolean {
    return this._validationLoading;
  }

  customErrors = {
    emptyCaptcha: false,
    invalidCaptcha: false,
    invalidEmail: false,
    userExists: false,
    validationInProgress: false,
  };

  recaptchaKey;
  countryPrefix: string;
  phoneCode: number;
  authType = AuthTypes.Register;
  assetsDir = '/';
  constructor(
    /* private fbLoginService: FbLoginService, */
    private dialogService: DialogService,
    private translateService: TranslocoService,
    private dialogRef: MatDialog,
    private shopConfigService: ShopConfigService
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.assetsDir = environment.deploy_url;
    }
    this.recaptchaKey = environment.recaptcha;
    sessionStorage.setItem('activeRegistrationOption', RegistrationOption.Email);
  }

  getFormControl(name: string) {
    return this.form.get(name);
  }

  getErrorKeys(controlName: string): string[] {
    const control = this.getFormControl(controlName);
    return Object.keys(control.errors);
  }

  loginWithFacebook() {
    this.loading = true;
    /*  this.fbLoginService.facebookRedirect(); */
    this.dialogService.closeDialogs();
  }

  changeStep() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid && !this.validationLoading) {
      if (this.form.get('phone_number')?.value) {
        this.form.addControl('phone_country_code', new FormControl(this.countryPrefix));
      }

      this.stepChange.emit();
    } else if (this.validationLoading) {
      this.customErrors.validationInProgress = true;
    }
  }

  onRegistrationOptionChange(option: RegistrationOption) {
    sessionStorage.setItem('activeRegistrationOption', option);
  }

  onCountryPrefixChange(prefix: string) {
    this.countryPrefix = prefix;
  }

  onCaptchaChange() {
    this.customErrors.emptyCaptcha = false;
    this.customErrors.invalidCaptcha = false;
  }

  get registrationOptions() {
    return RegistrationOption;
  }

  closeDialog() {
    this.dialogRef.closeAll();
  }

  getLanguage(): 'nl' | 'en' {
    const currentLang = this.translateService.getActiveLang();
    return currentLang === 'nl' ? currentLang : 'en';
  }

  setFacebookRedirect(param) {
    this.shopConfigService.facebookRedirect = param;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
