<div class="row" *ngIf="paymentMethod">
  <div class="col-12">
    <div class="payment-method-container" (click)="setSelected()">
      <div class="row">
        <div class="col-9 flex-col">
          <app-radio-btn-check [isDisabled]="true" [value]="active"> </app-radio-btn-check>
          <div class="payment-thumbnail-container">
            <img class="payment-thumbnail" [src]="paymentMethod.logo_image" />
          </div>
          <div class="align-self-center">
            <span class="payment-name">{{ paymentMethod.display_name }}</span>
          </div>
        </div>

        <div class="col-3 text-right align-self-center price-desktop">
          <span class="payment-price">
            <ng-container *ngIf="paymentMethod.transaction_fee > 0">
              {{ paymentMethod.transaction_fee | currency: 'EUR' }}
            </ng-container>
            <ng-container *ngIf="paymentMethod.transaction_fee == 0">
              {{ 'payment.free' | transloco }}
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row variants-desktop" *ngIf="active && paymentMethod.variants.length">
  <div class="col-12 mb-2 select-bank">
    {{ 'payment.select_your_bank' | transloco }}
  </div>
  <div class="payment-variants-container">
    <payment-variant
      *ngFor="let variant of paymentMethod.variants"
      class="payment-variant"
      (setSelectedVariatnEmiter)="setSelectedVariant($event)"
      [active]="selectedVariant === variant.name"
      [paymentMethod]="variant"
      [color]="eventColor"
    ></payment-variant>
  </div>
</div>
