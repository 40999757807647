import { ComponentCanDeactivate } from './component-can-deactivate';
import { Directive } from '@angular/core';

@Directive()
export abstract class CanDeactivate extends ComponentCanDeactivate {
  abstract canCloseTab(): boolean;

  canDeactivate(): boolean {
    return this.canCloseTab();
  }
}
