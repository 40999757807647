import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthDialogComponent } from '../components/auth-dialog/auth-dialog.component';
import { DialogService } from './dialog.service';
import { AuthDialogType } from '../interfaces/auth.interface';
import { User } from '../interfaces/user.interface';
import { AuthAPIService } from './auth-api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authChecked: boolean;
  meChange: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private authApiService: AuthAPIService
  ) {}

  openAuthDialog(dialogConfig?: DialogConfig) {
    const config = {
      width: dialogConfig?.width || '680px',
      data: dialogConfig?.data,
    };
    this.dialogService.openDialog(AuthDialogComponent, {
      panelClass: ['dialog-auth', 'dialog-primary'],
      ...config,
    });
  }
  checkAuthAfterRegister() {
    this.authApiService.profile().subscribe({
      next: (me: User) => {
        this.setMe(me);
        this.dialogService.openDialog(AuthDialogComponent, {
          width: '600px',
          data: {
            type: AuthDialogType.ConfirmationCode,
          },
        });
      },
      error: () => {
        this.authChecked = true;
      },
    });
  }

  setMe(me: User) {
    this.meChange.next(me);
  }
}
export interface DialogConfig {
  width?: string;
  data?: any;
}
