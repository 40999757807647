<div class="app-header-logo">
  <a class="app-logo">
    <img src="{{ assetsDir }}assets/logo/appic-logo-black.svg" alt="logo" />
  </a>
</div>
<div class="align-self-center lang-container">
  <mat-form-field class="lang-select" floatLabel="never">
    <mat-select [(ngModel)]="selectedLanguage" (valueChange)="onLanguageChange($event)">
      <mat-option *ngFor="let language of getAvailableLangs()" [value]="language">
        {{ language | uppercase }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
