import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UniversalDeviceDetectorService extends DeviceDetectorService {
  deviceInfo: DeviceInfo;
  constructor(@Inject(PLATFORM_ID) platformId: any) {
    super(platformId);
    if (isPlatformBrowser(platformId)) {
      this.deviceInfo = super.getDeviceInfo();
    }
  }
}
