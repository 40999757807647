import { HostListener, Directive } from '@angular/core';
import { ShopApiService } from '../services/shop-api.service';
import { ShopConfigService } from '../services/shop-config.service';

@Directive()
export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): boolean;

  constructor(
    protected shopApiService: ShopApiService,
    protected shopConfigService: ShopConfigService
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
  /* 
 commented out due to poor browser support and random cart cancellation
  @HostListener('window:unload', ['$event'])
  unload($event: any) {
    if (!this.canDeactivate() && this.shopConfigService.shoppingCart) {
      this.shopApiService.deleteShoppingCart(this.shopConfigService.shoppingCart.uuid).subscribe(
        () => {
          this.shopConfigService.shoppingCart = undefined;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  } */
}
