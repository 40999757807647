<div class="loading-container" *ngIf="loading">
  <div class="spinner-container">
    <mat-spinner class="primary" [diameter]="40"></mat-spinner>
  </div>
</div>
<div class="row auth-form" [ngClass]="{ loading: loading }">
  <div class="col-12">
    <p class="text-center success-msg" *ngIf="successMessage" [innerHTML]="'global.' + successMessage | transloco"></p>
    <a [routerLink]="['/']" *ngIf="successMessage" class="btn btn-primary d-block w-100 btn-submit mt-4 text-center">{{
      'global.go_to_appic_events' | transloco
    }}</a>

    <form class="auth-form" [formGroup]="formPassword" *ngIf="showPasswordForm">
      <div class="form-field-container" (appCapsLock)="capsOn = $event">
        <div class="form-field-label">
          {{ 'form.field.password' | transloco }}
        </div>
        <div class="caps-tooltip" *ngIf="capsOn && passwordInput.focused">Caps Lock</div>
        <mat-form-field appearance="outline" class="form-field form-field-password" [floatLabel]="'never'">
          <input
            #passwordInput="matInput"
            matInput
            [type]="hide ? 'password' : 'text'"
            placeholder="{{ 'form.field.password' | transloco }}"
            formControlName="password1"
            required
            #input
            (keyup)="checkCondition(input)"
          />
          <svg-icon
            matSuffix
            *ngIf="!hide"
            class="form-field-icon"
            src="{{ assetsDir }}assets/icons/icon-eye-hide.svg"
            (click)="hide = !hide"
          ></svg-icon>
          <svg-icon
            matSuffix
            *ngIf="hide"
            class="form-field-icon"
            src="{{ assetsDir }}assets/icons/icon-eye-show.svg"
            (click)="hide = !hide"
          ></svg-icon>

          <mat-error *ngIf="getFormControl('password1').invalid">
            <ng-container *ngIf="getFormControl('password1').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field-container" (appCapsLock)="capsOn = $event">
        <div class="form-field-label">
          {{ 'form.field.repeat_password' | transloco }}
        </div>
        <div class="caps-tooltip" *ngIf="capsOn && repeatPasswordInput.focused">Caps Lock</div>
        <mat-form-field appearance="outline" class="form-field form-field-password" [floatLabel]="'never'">
          <input
            #repeatPasswordInput="matInput"
            matInput
            [type]="hide ? 'password' : 'text'"
            placeholder="{{ 'form.field.repeat_password' | transloco }}"
            formControlName="password2"
            required
          />
          <svg-icon
            matSuffix
            *ngIf="!hide"
            class="form-field-icon"
            src="{{ assetsDir }}assets/icons/icon-eye-hide.svg"
            (click)="hide = !hide"
          ></svg-icon>
          <svg-icon
            matSuffix
            *ngIf="hide"
            class="form-field-icon"
            src="{{ assetsDir }}assets/icons/icon-eye-show.svg"
            (click)="hide = !hide"
          ></svg-icon>
          <mat-error *ngIf="getFormControl('password2').invalid">
            <ng-container *ngIf="getFormControl('password2').errors.required">
              {{ 'form.error.required' | transloco }}
            </ng-container>
            <ng-container *ngIf="getFormControl('password2').errors.matchOther">
              {{ 'form.error.password_not_same' | transloco }}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="passwordConditions" class="password-requirements">
        <div>{{ 'form.password_conditions' | transloco }}:</div>
        <ul>
          <li
            *ngFor="let condition of passwordConditions"
            class="password-condition"
            id="{{ condition.type }}"
            [class.active]="isConditionMet(condition)"
          >
            <svg-icon class="icon-check" src="{{ assetsDir }}assets/icons/icon-check-circle.svg"></svg-icon>
            <span>{{ condition.name }}</span>
          </li>
        </ul>
      </div>
      <button class="btn btn-primary d-block w-100 btn-submit mt-5" (click)="onPasswordSubmit()">
        {{ 'global.change_password' | transloco }}
      </button>
    </form>
  </div>
</div>
