<div class="auth-panel fadeIn" *ngIf="authDialogType">
  <div class="auth-panel-header text-center">
    <div class="row m-0">
      <div class="close-icon-container">
        <svg-icon class="icon-close" src="{{ assetsDir }}assets/icons/icon-close.svg" (click)="closeDialog()" />
      </div>
      <div class="col-12 header" *ngIf="header">
        {{ 'global.' + header | transloco }}
      </div>
    </div>
  </div>
  <div class="auth-panel-body">
    <div class="row m-0 justify-content-center">
      <div class="col-12 col-md-10">
        <app-auth-form-login
          (forgotRedirect)="closeDialog()"
          (loginSuccess)="loginSuccess()"
          *ngIf="authDialogType === 'login'"
        ></app-auth-form-login>
        <app-auth-form-sign-up-with
          *ngIf="authDialogType === 'signUpWith'"
          [form]="signUpWithForm"
          (stepChange)="onSignUpWithStepChange()"
          (countryPrefixChange)="onCountryPrefixChange($event)"
        ></app-auth-form-sign-up-with>
        <app-auth-form-update-details
          (detailsUpdatedSuccess)="detailsUpdatedSuccess()"
          [data]="data"
          *ngIf="authDialogType === 'updateDetails'"
        ></app-auth-form-update-details>
        <app-auth-form-confirmation-code
          *ngIf="authDialogType === 'confirmationCode'"
          (stepChange)="onConfirmationCodeStepChange()"
        ></app-auth-form-confirmation-code>
        <app-auth-form-user-details
          *ngIf="authDialogType === 'userProfileDetails'"
          [data]="data"
          (detailsUpdatedSuccess)="detailsUpdatedSuccess()"
        ></app-auth-form-user-details>
        <app-auth-form-password
          [signUpWithForm]="signUpWithForm"
          [passwordForm]="passwordForm"
          *ngIf="authDialogType === 'setPassword'"
          (stepChange)="onSetPasswordStepChange()"
        ></app-auth-form-password>
      </div>
    </div>
  </div>
  <div class="auth-panel-bottom" *ngIf="authDialogType !== 'userProfileDetails'">
    <div class="row m-0 justify-content-center">
      <div class="col-12 col-md-10">
        <p *ngIf="authDialogType === 'login'" (click)="changeType('signUpWith')" class="auth-panel-bottom-info">
          {{ 'global.dont_have_an_account' | transloco }}
          <span class="text-color-primary cta-btn">{{ 'global.create_account' | transloco }}</span>
        </p>
        <p *ngIf="authDialogType === 'signUpWith'" (click)="changeType('login')" class="auth-panel-bottom-info">
          {{ 'global.already_got_an_account' | transloco }}
          <span class="text-color-primary cta-btn">{{ 'global.sign_in' | transloco }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
