import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { ResetPasswordRequest } from 'src/app/home/interfaces/auth.interface';
import { CanDeactivate } from 'src/app/home/can-deactivate/can-deactivate';
import { ShopConfigService } from 'src/app/home/services/shop-config.service';

@Component({
  selector: 'password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['../forms.component.scss'],
})
export class ResetPasswordFormComponent extends CanDeactivate implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  @Output() toggleFormsEmiter = new EventEmitter<string>();
  success = false;

  constructor(
    private fB: UntypedFormBuilder,
    protected shopApiService: ShopApiService,
    protected shopConfigService: ShopConfigService
  ) {
    super(shopApiService, shopConfigService);
  }

  ngOnInit() {
    this.resetPasswordForm = this.fB.group({
      email: this.fB.control('', [Validators.required, Validators.email]),
    });
  }

  getFormControl(name) {
    return this.resetPasswordForm.get(name);
  }

  onSubmit() {
    const resetPasswordRequest: ResetPasswordRequest = this.resetPasswordForm.value;
    this.shopApiService.postResetPassword(resetPasswordRequest).subscribe(
      (response: any) => {
        if (response.status === 1) {
          this.success = true;
        } else if (response.status === 0) {
          this.getFormControl('email').setErrors({
            incorrect_email: true,
          });
        }
      },
      (error) => {
        console.warn(error);
      }
    );
  }

  toggleForms(form) {
    this.toggleFormsEmiter.emit(form);
  }

  getForm(): UntypedFormGroup {
    return this.resetPasswordForm;
  }

  canCloseTab(): boolean {
    return !this.getForm().dirty;
  }
}
