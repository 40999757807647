import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthDialogType, RegisterRequest } from '../../interfaces/auth.interface';
import { matchOtherValidator } from 'src/app/utils/matchOtherValidator';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'], // Different type of dialog
  encapsulation: ViewEncapsulation.None,
})
export class AuthDialogComponent implements OnInit {
  authDialogType: AuthDialogType;
  header;
  countryPrefix: string;
  signUpWithForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });

  passwordForm = new UntypedFormGroup({
    captcha_token: new UntypedFormControl(''),
    password: new UntypedFormControl('', [Validators.required]),
    confirm_password: new UntypedFormControl('', [Validators.required, matchOtherValidator('password')]),
  });

  registrationForm = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required]),
    last_name: new UntypedFormControl('', [Validators.required]),
    use_nickname: new UntypedFormControl(false),
    nickname: new UntypedFormControl(''),
    gender: new UntypedFormControl('', [Validators.required]),
    birth_date: new UntypedFormControl('', [Validators.required]),
    birth_date_dd: new UntypedFormControl(''),
    birth_date_mm: new UntypedFormControl(''),
    birth_date_yyyy: new UntypedFormControl(''),
    city: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    language: new UntypedFormControl('', [Validators.required]),
    captcha_token: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AuthDialogComponentData
  ) {}

  loginSuccess() {
    if (this.data?.loginSuccessCb) {
      this.data.loginSuccessCb();
    }
    this.dialogRef.close();
  }

  detailsUpdatedSuccess() {
    if (this.data?.detailsSuccessCb) {
      this.data.detailsSuccessCb();
    }
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.authDialogType = this.data?.type || AuthDialogType.Login;
    this.setHeader();
  }

  onCountryPrefixChange(prefix) {
    this.countryPrefix = prefix;
  }

  setHeader() {
    switch (this.authDialogType as AuthDialogType) {
      case AuthDialogType.Login: {
        this.header = 'sign_in';
        break;
      }
      case AuthDialogType.UpdateDetails: {
        this.header = 'update_details';
        break;
      }
      case AuthDialogType.SignUpWith: {
        this.header = 'sign_up_with';
        break;
      }
      case AuthDialogType.ConfirmationCode: {
        this.header = 'confirmation_code';
        break;
      }
      case AuthDialogType.SetPassword: {
        this.header = 'set_password';
        break;
      }
      case AuthDialogType.UserProfileDetails: {
        this.header = 'create_account';
        break;
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeType(type) {
    this.authDialogType = type;
    this.setHeader();
  }

  onSignUpWithStepChange() {
    this.authDialogType = AuthDialogType.SetPassword;
    this.setHeader();
  }

  onSetPasswordStepChange() {
    this.authDialogType = AuthDialogType.ConfirmationCode;
    this.setHeader();
  }

  onConfirmationCodeStepChange() {
    this.authDialogType = AuthDialogType.UserProfileDetails;
    this.setHeader();
  }
}

export interface AuthDialogComponentData {
  loginSuccessCb?: Function;
  detailsSuccessCb?: Function;
  registerRequest?: Omit<RegisterRequest, 'captcha_token' | 'context'>;
  type?: AuthDialogType;
}
