import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, OnChanges, Output, EventEmitter, NgZone } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ShoppingCart } from '../../interfaces/shopping-cart.interface';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderPanelOptions } from '../../interfaces/component.interface';
import { ShopConfigService } from '../../services/shop-config.service';

@Component({
  selector: 'order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnDestroy, OnInit, OnChanges {
  @Input('shopingCart') sc: ShoppingCart;
  @Input('orderPanelOptions') options: OrderPanelOptions;
  @Output() submitOrderEmiter = new EventEmitter();
  @Output() nextStepEmiter = new EventEmitter();
  timerSub: Subscription;
  expireTimer: number;
  expand = false;
  objectKeys = Object.keys;
  warningColor: boolean;
  constructor(
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private router: Router,
    private shopConfigService: ShopConfigService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.warningColor = false;

    if (this.sc) {
      if (this.sc.summary) {
        const now = new Date(Date.now());
        const exp = new Date(this.sc.reserved_until.replace(/-/g, '/'));
        const seconds = Math.floor((+exp - +now) / 1000);

        if (exp > now) {
          this.startTimer(seconds);
        }
      }
    } else if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  ngOnChanges(): void {
    if (this.sc) {
      if (this.sc.summary) {
        const now = new Date(Date.now());
        const exp = new Date(this.sc.reserved_until.replace(/-/g, '/'));
        const seconds = Math.floor((+exp - +now) / 1000);

        if (exp > now) {
          this.startTimer(seconds);
        }
      }

      if (!this.sc.summary.products.length && this.timerSub) {
        this.timerSub.unsubscribe();
      }
    } else if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }

  public startTimer(seconds) {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
    this.timerSub = timer(0, 1000)
      .pipe(
        take(seconds + 1),
        map((value) => seconds - value)
      )
      .subscribe({
        next: (value) => {
          this.expireTimer = value;
          if (!this.warningColor && this.expireTimer < 60) {
            this.warningColor = true;
          }
          if (this.expireTimer === 0) {
            this.shopConfigService.shoppingCart = null;
            this.appComponent.openDialog(
              'application_errors.time_for_ordering_tickets_was_expired',
              (callback) => {
                if (callback) {
                  this.router.navigate(['../tickets'], {
                    relativeTo: this.route,
                  });
                }
              },
              null,
              'application_errors.time_expired'
            );
          }
        },
      });
  }

  goNextStep() {
    if (this.options) {
      if (this.options.nextStep) {
        this.nextStepEmiter.emit();
        if (this.options.nextStep === 'submitOrder') {
          this.options.nextButtonDisabled = true;
          setTimeout(() => {
            this.options.nextButtonDisabled = false;
          }, 4000);
          this.submitOrderEmiter.emit();
        } else {
          this.router.navigate([`../${this.options.nextStep}`], {
            relativeTo: this.route,
            queryParams: this.getQueryParams(),
          });
        }
      }
    }
  }

  getQueryParams() {
    const queryParams = {
      transaction: this.sc.uuid,
    };

    Object.keys(queryParams).forEach((key) => (queryParams[key] === null || queryParams[key] === undefined) && delete queryParams[key]);

    return queryParams;
  }
}
