import { Component, Input, ViewEncapsulation, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { PaymentVariant } from '../../interfaces/shopping-cart.interface';

@Component({
  selector: 'payment-variant',
  templateUrl: './payment-variant.component.html',
  styleUrls: ['./payment-variant.component.scss'],
})
export class PaymentVariantComponent implements OnInit {
  @Input('color') eventColor: string;
  @Input('paymentMethod') paymentVariant: PaymentVariant;
  @Input('active') active: boolean;
  borderColor: string;
  hover: boolean;
  @Output() setSelectedVariatnEmiter = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  setSelectedVariant() {
    this.setSelectedVariatnEmiter.emit(this.paymentVariant.name);
  }
}
