import { Component, OnInit, Input, EventEmitter, Output, Inject, PLATFORM_ID } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Countries } from 'src/app/home/interfaces/forms.interface';
import { ShopApiService } from 'src/app/home/services/shop-api.service';
import { ShopConfigService } from 'src/app/home/services/shop-config.service';

import { CanDeactivate } from 'src/app/home/can-deactivate/can-deactivate';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject, Observable, combineLatest } from 'rxjs';
import { ValidateEmailRequest, ValidateEmailResponse } from 'src/app/home/interfaces/auth.interface';
import { debounceTime, distinctUntilChanged, startWith, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AuthAPIService } from 'src/app/home/services/auth-api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DialogConfig } from '@angular/cdk/dialog';
import { AuthService } from 'src/app/home/services/auth.service';
import { City } from 'src/app/home/interfaces/geocoder.interface';

@Component({
  selector: 'details-form',
  templateUrl: './details-form.component.html',
  styleUrls: ['../forms.component.scss', './details-form.component.scss'],
})
export class DetailsFormComponent extends CanDeactivate implements OnInit {
  detailsForm: UntypedFormGroup;
  @Input('color') eventColor;
  countries: Countries;
  @Input('countries') set _countries(v: Countries) {
    this.countries = v;
  }

  @Output() nextStepEmiter = new EventEmitter();
  @Output() formSubmittableEmiter = new EventEmitter<boolean>();
  @Output() formInitEmitter = new EventEmitter<UntypedFormGroup>();

  now: Date = new Date();
  startDate: Date = new Date(2000, 1, 1);
  filteredCities: Subscription;
  cities;
  selectedCity;
  emailInput;
  emailValidated = false;
  constructor(
    private fB: UntypedFormBuilder,
    protected shopApiService: ShopApiService,
    protected shopConfigService: ShopConfigService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authApiService: AuthAPIService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authService: AuthService
  ) {
    super(shopApiService, shopConfigService);
  }

  ngOnInit() {
    this.detailsForm = this.fB.group({
      email: this.fB.control('', [Validators.required, Validators.email]),
      first_name: this.fB.control('', [Validators.required]),
      last_name: this.fB.control('', [Validators.required]),
      city: this.fB.control('', [Validators.required]),
      country: this.fB.control('', [Validators.required]),
      birth_date: this.fB.control('', [Validators.required]),
    });
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        const formData = JSON.parse(sessionStorage.getItem('formData'));
        if (formData) {
          const { birth_date, ...rest } = formData;
          this.detailsForm.patchValue({ ...rest, birth_date: new Date(birth_date) });
        }
      }
    }, 0);
    this.detailsForm
      .get('email')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model) => {
        if (model && model !== '') {
          this.emailValidated = false;
          this.emitFormSubmittable();
          this.emailInput = model;

          if (!this.getFormControl('email').invalid) {
            this.validateEmail();
          }
        }
      });
    combineLatest(this.watchFormExceptControl('email')).subscribe((value) => {
      this.detailsForm.updateValueAndValidity();

      this.emitFormSubmittable();
    });
    this.formInitEmitter.emit(this.detailsForm);
  }

  checkCity() {
    if (this.selectedCity !== this.getFormControl('city').value) {
      this.getFormControl('city').setErrors({
        select_city: true,
      });
    }
  }

  setCountry(value: City) {
    this.selectedCity = value.city;
    if (value.country) {
      this.getFormControl('country').setValue(value.country.toUpperCase());
    }
  }

  getFormControl(name) {
    return this.detailsForm.get(name);
  }

  getForm(): UntypedFormGroup {
    return this.detailsForm;
  }

  canCloseTab(): boolean {
    return !this.getForm().dirty;
  }

  emitFormSubmittable() {
    this.formSubmittableEmiter.emit(this.detailsForm.valid && this.emailValidated);
  }

  openLoginDialog() {
    const dialogConfig: DialogConfig = {
      data: { loginSuccessCb: this.onLoginOrUpdateDetailsCb.bind(this), detailsSuccessCb: this.onLoginOrUpdateDetailsCb.bind(this) },
    };
    this.authService.openAuthDialog(dialogConfig);
  }

  onLoginOrUpdateDetailsCb() {
    this.shopConfigService.appicRedirect === true;

    this.router.navigate([`../payment`], {
      relativeTo: this.route,
      queryParams: this.getQueryParams(),
    });
  }
  getQueryParams() {
    const queryParams = {
      transaction: this.shopConfigService.shoppingCart?.uuid,
    };

    Object.keys(queryParams).forEach((key) => (queryParams[key] === null || queryParams[key] === undefined) && delete queryParams[key]);

    return queryParams;
  }

  watchFormExceptControl(exceptionKeyControl: string): Array<Observable<any>> {
    return Object.entries(this.detailsForm.controls)
      .filter(([key]) => key !== exceptionKeyControl)
      .map(([key]) => {
        return this.detailsForm.controls[key].valueChanges.pipe(startWith(''));
      });
  }

  validateEmail() {
    if (this.getFormControl('email')?.value) {
      if (this.getFormControl('email')?.valid) {
        this.recaptchaV3Service
          .execute('validateEmail')
          .pipe(take(1))
          .subscribe((token) => {
            const body: ValidateEmailRequest = {
              email: this.getFormControl('email').value,
              captcha_token: token,
            };

            this.authApiService.validateEmail(body).subscribe(
              (res: ValidateEmailResponse) => {
                this.emailValidated = true;

                if (res?.code === 'user_exists') {
                  this.getFormControl('email')?.setErrors({ user_exists: true });
                }
                if (res?.code === 'not_deliverable') {
                  this.getFormControl('email')?.setErrors({ not_deliverable: true });
                }
                if (res.is_valid) {
                  this.emitFormSubmittable();
                }
                this.getFormControl('email').markAsTouched();
              },
              (error) => {
                this.emailValidated = false;
              }
            );
          });
      }
    }
  }
}
