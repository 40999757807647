import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/home/services/dialog.service';

export interface ErrorDialogDialogData {
  error: string;
  otherText?: string;
  title?: string;
  withButton: boolean;
  btnText?: string;
}

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['../dialog.component.scss', './error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogDialogData,
    private dialogService: DialogService
  ) {}

  onNoClick(): void {
    this.dialogService.closeDialogs();
  }
}
